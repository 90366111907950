// src/components/ContactForm.js
import React, { useEffect, useState } from 'react'
import RadioButtonInsideButton from '../styles/buttons/RadioButtonInsideButton'
import InputOne from '../styles/fields/InputOne'
import { colors, TextStyles, gradients } from '../styles/index'
import useIsMobile from '../hooks/useIsMobile'
import HoverButtonOne from '../styles/buttons/HoverButtonOne'
import { ContactFormSubmitModal } from './ContactFormSubmitModal'
import emailjs from '@emailjs/browser'

const ContactForm = () => {
  // const emailRef = useRef < HTMLInputElement > (null);
  // const nameRef = useRef < HTMLInputElement > (null);
  const isMobile = useIsMobile()
  const isBelow1350 = useIsMobile(1350)
  const [selectedRole, setSelectedRole] = useState('')
  const [selectedTimeframe, setSelectedTimeframe] = useState('')
  const PrivacyText = isMobile ? TextStyles.TextRegular12 : TextStyles.TextMedium16
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
  })
  const [errors, setErrors] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => emailjs.init('FmJEVvg4nMgWQz8Lc'), [])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value)
  }

  const handleTimeframeChange = (e) => {
    setSelectedTimeframe(e.target.value)
  }

  // Validation function
  const validateForm = () => {
    let formErrors = {}
    if (!formData.name.trim()) {
      formErrors.name = 'Name is required.'
    }
    if (!formData.company.trim()) {
      formErrors.company = 'Company name is required.'
    }
    if (!formData.email.trim()) {
      formErrors.email = 'Email is required.'
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email is invalid.'
    }
    if (!selectedRole) {
      formErrors.role = 'Please select your role.'
    }
    if (!selectedTimeframe) {
      formErrors.timeframe = 'Please select a timeframe.'
    }
    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault()
    const isValid = validateForm()
    const serviceId = 'service_z6y0mpd'
    const templateId = 'template_3yki3f8'
    const scriptURL =
      'https://script.google.com/macros/s/AKfycby5hcA6loTWBOTOl5slXYTtwtfHV1DrwrnxGSGxXIe7-_kKvpzeEHG2yBqYUK5m5vv7IQ/exec'

    if (isValid) {
      const res = await emailjs.send(serviceId, templateId, {
        from_name: formData.name,
        to_name: formData.name,
        reply_to: formData.email,
      })
      if (res.status === 200) {
        console.log('RES', res)
        await fetch(scriptURL, {
          method: 'POST',
          mode: 'no-cors', // 'no-cors' to prevent any issues with CORS policy
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        })
        setIsModalOpen(true)
        setErrors({})
        setFormData({
          name: '',
          company: '',
          email: '',
        })
      }
    } else {
      // Focus on the first input with an error (optional)
      const firstErrorField = document.querySelector('input:invalid')
      if (firstErrorField) firstErrorField.focus()
    }
  }

  const FormText = TextStyles.TextRegular20
  const FormInput = TextStyles.TextRegular16

  return (
    <div className='w-full min-h-screen flex flex-row justify-between items-start bg-white py-20 md:py-32 px-4 md:px-16 lg:px-16'>
      <div className='flex flex-col'>
        <div className='w-full max-w-[849px] space-y-4 mb-8'>
          {isBelow1350 && <TextStyles.TextRegular18 className='pb-6'>Contact Us</TextStyles.TextRegular18>}
          <TextStyles.H3 className='inline'>
            If you have any questions, suggestions, or would like to know more about our product, please fill out the
            form below.&nbsp;
          </TextStyles.H3>
          <TextStyles.GradientH3 gradient={gradients.gradientTwo} className='inline'>
            We will contact you as soon as possible.
          </TextStyles.GradientH3>
        </div>

        <form className='w-full max-w-3xl grid gap-6' onSubmit={handleSubmit}>
          {/* First Line: Name and Company */}
          <div className='grid grid-cols-1 md:flex md:flex-row gap-4 items-center'>
            <FormText className='whitespace-nowrap'>My name is*</FormText>
            <InputOne
              placeholder='Enter your Name'
              name='name'
              // ref={nameRef}
              textStyle={FormInput}
              value={formData.name}
              onChange={handleInputChange}
              className='w-full'
            />
            <FormText className='whitespace-nowrap'>and I work in*</FormText>
            <InputOne
              placeholder='Company Name'
              name='company'
              textStyle={FormInput}
              value={formData.company}
              onChange={handleInputChange}
              className='w-full'
            />
          </div>

          {/* Second Line: Role Selection */}
          <div className='flex flex-wrap items-center gap-4'>
            <FormText className='whitespace-nowrap'>My role there as*</FormText>
            <div className='flex flex-wrap gap-2'>
              <RadioButtonInsideButton
                name='role'
                value='Patient'
                checked={selectedRole === 'Patient'}
                onChange={handleRoleChange}
                textStyle={FormInput}
              />
              <RadioButtonInsideButton
                name='role'
                value='Provider'
                checked={selectedRole === 'Provider'}
                onChange={handleRoleChange}
                textStyle={FormInput}
              />
              <RadioButtonInsideButton
                name='role'
                value='Investor'
                checked={selectedRole === 'Investor'}
                onChange={handleRoleChange}
                textStyle={FormInput}
              />
              <RadioButtonInsideButton
                name='role'
                value='Other'
                checked={selectedRole === 'Other'}
                onChange={handleRoleChange}
                textStyle={FormInput}
              />
            </div>
          </div>

          {/* Third Line: Email */}
          <div className='grid grid-cols-1 md:flex md:flex-row gap-4 items-center'>
            <FormText className='whitespace-nowrap'>Feel free to contact me at*</FormText>
            <InputOne
              placeholder='Email'
              name='email'
              // ref={emailRef}
              textStyle={FormInput}
              value={formData.email}
              onChange={handleInputChange}
              className='w-full'
            />
            {!isMobile && <FormText className='whitespace-nowrap'>to begin conversation</FormText>}
          </div>

          {/* Fourth Line: Answer Timeframe */}
          <div className='flex flex-wrap items-center gap-4'>
            <FormText className='whitespace-nowrap'>I would like to get an answer*</FormText>
            <div className='flex flex-wrap gap-2'>
              <RadioButtonInsideButton
                name='timeframe'
                value='Soon'
                checked={selectedTimeframe === 'Soon'}
                onChange={handleTimeframeChange}
                textStyle={FormInput}
              />
              <RadioButtonInsideButton
                name='timeframe'
                value='No Rush'
                checked={selectedTimeframe === 'No Rush'}
                onChange={handleTimeframeChange}
                textStyle={FormInput}
              />
            </div>
          </div>

          {/* Submit Button and Error Messages */}
          <div className='w-full flex flex-col items-center' onClick={handleSubmit}>
            <HoverButtonOne width='100%'>Submit</HoverButtonOne>
            {Object.values(errors).length > 0 && (
              <TextStyles.TextRegular12 color={colors.sysRed} className='mt-2 text-center'>
                {Object.values(errors)[0]}
              </TextStyles.TextRegular12>
            )}
            <div className='mt-2 text-center flex flex-row'>
              <PrivacyText>By Submitting this form you agree to our &nbsp;</PrivacyText>
              <PrivacyText className='underline inline'>Privacy Policy</PrivacyText>
            </div>
            {isMobile && (
              <div>
                <div className='mt-2 text-center flex flex-row'>
                  <TextStyles.TextRegular14 color={colors.grey137} className='pt-12'>
                    Don&apos;t like the forms? Drop us a line via email or call:
                  </TextStyles.TextRegular14>
                </div>
                <div className='space-x-4 flex items-center'>
                  <TextStyles.UnderlinedRegular18 className='inline'>
                    evan@edenonearth.ai
                  </TextStyles.UnderlinedRegular18>
                  <TextStyles.Dot color={colors.darkBlue} className='inline' />
                  <TextStyles.UnderlinedRegular18 className='inline'>(732) 477-4149</TextStyles.UnderlinedRegular18>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>

      {!isBelow1350 && (
        <div className='flex flex-col text-right'>
          <TextStyles.TextRegular18 className='pb-6'>Contact Us</TextStyles.TextRegular18>
          <TextStyles.TextRegular14 color={colors.grey137} className='pb-2'>
            Don&apos;t like the forms? Drop us a line via email or call:
          </TextStyles.TextRegular14>
          <div className='space-x-4 flex items-center'>
            <TextStyles.UnderlinedRegular18 className='inline'>evan@edenonearth.ai</TextStyles.UnderlinedRegular18>
            <TextStyles.Dot color={colors.darkBlue} className='inline' />
            <TextStyles.UnderlinedRegular18 className='inline'>(732) 477-4149</TextStyles.UnderlinedRegular18>
          </div>
        </div>
      )}

      {isModalOpen && <ContactFormSubmitModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />}
    </div>
  )
}

export default ContactForm
