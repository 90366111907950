import React, { useRef, useEffect } from 'react';
import { TextStyles, colors } from '../../styles/index'; // Import TextStyles
import useIsMobile from '../../hooks/useIsMobile'; // Import the mobile detection hook
import './Hero.module.scss'; // Import the SCSS module

const Hero = () => {
  const isMobile = useIsMobile(); // Use the custom hook to check if the screen is mobile
  const Text = isMobile ? TextStyles.H2Shadow : TextStyles.H1Shadow;
  const videoRef = useRef(null);

  // Select the appropriate background image based on screen size
  const backgroundImage = isMobile
    ? '/static/Hero/hero_gradient_mobile.svg' // Mobile-specific background image
    : '/static/Hero/hero_gradient_desktop.svg'; // Desktop-specific background image

  useEffect(() => {
    // Attempt to programmatically play the video on component mount
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.log('Autoplay prevented:', error);
        });
      }
    };

    playVideo();

    // Retry play when the video is interacted with, ensuring iOS compliance
    document.addEventListener('touchstart', playVideo, { once: true });

    // Cleanup event listener
    return () => {
      document.removeEventListener('touchstart', playVideo);
    };
  }, []);

  return (
    <div
      className="relative w-full overflow-hidden"
      style={{ height: isMobile ? '550px' : '1080px' }} // Adjust height based on screen size
    >
      {/* Video Background */}
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="/static/Hero/hero_video.mp4" // Correctly capitalized path
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
      ></video>

      {/* Overlay with Gradient that has the Mask Cutout */}
      <div
        className="absolute top-0 left-0 w-full h-full"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
        }}
      />

      {/* Centered Text */}
      <div
        className="relative z-4 flex flex-col pt-[200px] md:pt-0 items-center justify-center h-full space-y-2"
        style={{ paddingBottom: isMobile ? '150px' : '100px' }} // Adjust padding based on screen size
      >
        <Text color="white" className="max-w-[500px] w-full text-center">
          We Bring
        </Text>
        <Text color={colors.white} className="max-w-[500px] w-full text-center">
          Heaven to
        </Text>
        <Text color="white" className="max-w-[500px] w-full text-center">
          Healthcare
        </Text>
      </div>

      {/* SVG Arc Shape at the Bottom */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="absolute bottom-0 left-0 w-full"
        viewBox="0 0 1440 125"
        fill="none"
        style={{
          width: '100%',
          height: 'auto',
        }}
      >
        <path
          d="M1440 103.289L1366.31 83.8414C942.686 -27.9471 497.314 -27.9471 73.6944 83.8414L0 103.289V126.289H1440V103.289Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default Hero;
