import React from 'react'
import PartnersCarousel from '../components/AboutComponents/PartnersCarousel'
import EdenDescription from '../components/AboutComponents/EdenDescription'
import EdenWorkflow from '../components/AboutComponents/EdenWorkflow'

const About = () => {
  return (
    <div className='h-[961px] lg:h-[1530px]'>
      <div>
        <PartnersCarousel />
      </div>
      <div className='mt-[167px] lg:mt-[242px]'>
        <EdenDescription />
      </div>
      <div className='mt-[167px] lg:mt-[242px]'>
        <EdenWorkflow />
      </div>
    </div>
  )
}

export default About
