import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import styles from './LineAnimation.module.scss';

const LineAnimation = ({ isVisible, sectionIndex, isContentLoaded }) => {
  const pathRef = useRef(null);
  const blackPathRef = useRef(null);

  const [pathLength, setPathLength] = useState(0);
  const [dashOffset, setDashOffset] = useState(0); // This controls the visible part of the colored path
  const [circlePosition, setCirclePosition] = useState(null);
  const [isLgScreen, setIsLgScreen] = useState(window.innerWidth >= 1024);
  const [scale, setScale] = useState(1);

  // Adjust circle percentages and translateY based on screen size
  const circlePercentages = isLgScreen
    ? [0.13, 0.22, 0.38, 0.50, 0.58, 0.72, 0.87] // for non-mobile
    : [0.15, 0.26, 0.33, 0.42, 0.50, 0.59, 0.72]; // for mobile

  const translateYValue = isLgScreen ? 55 : 40; // TranslateY value for mobile and non-mobile

  useEffect(() => {
    const handleResize = () => {
      const isLarge = window.innerWidth >= 1024;
      setIsLgScreen(isLarge);
      setScale(isLarge ? 1 : 1.1);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const blackPath = blackPathRef.current;
    if (blackPath) {
      setPathLength(blackPath.getTotalLength());
    }
  }, []);

  // Function to calculate the position on the path at a given length
  const calculatePositionAtLength = (length) => {
    if (pathRef.current) {
      const point = pathRef.current.getPointAtLength(length);
      return { x: point.x, y: point.y };
    }
    return { x: 0, y: 0 };
  };

  const startPercentage = circlePercentages[sectionIndex - 2] || 0;
  const endPercentage = circlePercentages[sectionIndex - 1] || 0;

  // This effect updates the circle's position progressively along the path and updates the dashOffset
  useEffect(() => {
    if (pathLength > 0 && pathRef.current) {
      const start = pathLength * startPercentage;
      const end = pathLength * endPercentage;

      const lengthArray = Array.from({ length: 100 }, (_, i) => start + ((end - start) / 100) * i);
      let currentIndex = 0;

      const updatePosition = () => {
        if (currentIndex < lengthArray.length) {
          const newPos = calculatePositionAtLength(lengthArray[currentIndex]);
          setCirclePosition(newPos);
          // Set dashOffset to control the **colored** path behind the circle
          setDashOffset(lengthArray[currentIndex]);
          currentIndex += 1;
          requestAnimationFrame(updatePosition);
        }
      };

      updatePosition();
    }
  }, [sectionIndex, pathLength, isLgScreen]); // Recalculate if screen size changes

  return (
    <motion.div
      className={styles.container}
      animate={{ translateY: `-${(sectionIndex - 1) * translateYValue}vh` }} // Dynamically set translateY
      transition={{ type: 'spring', stiffness: 100, damping: 30 }}>
      <div>
        <svg
          className={styles.animationSVG}
          width="500"
          height="h-auto"
          viewBox="0 0 418 3000"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: `scale(${scale})` }}>


          {/* Colored Path with Gradient */}
          <path
            ref={pathRef}
            d="M3 3L333.284 336.009C378.522 381.621 378.523 455.179 333.284 500.791L84.7165 751.409C39.4775 797.021 39.4775 870.578 84.7165 916.191L333.284 1166.81C378.522 1212.42 378.523 1285.98 333.284 1331.59L126.622 1539.96C58.1839 1608.96 58.1839 1720.24 126.622 1789.24L290.869 1954.84C359.507 2024.05 359.277 2135.72 290.355 2204.64L128.158 2366.84C59.0351 2435.96 59.0351 2548.04 128.158 2617.16L415 2904"
            stroke="rgb(1,1,34)" // Apply gradient here
            strokeWidth="5"
            strokeLinecap="round"
            strokeDasharray={pathLength} // Total length of the colored path
            strokeDashoffset={dashOffset} // Reveal the colored path **behind** the circle
          />

          {/* Black Path */}
          <path
            ref={blackPathRef}
            d="M3 3L333.284 336.009C378.522 381.621 378.523 455.179 333.284 500.791L84.7165 751.409C39.4775 797.021 39.4775 870.578 84.7165 916.191L333.284 1166.81C378.522 1212.42 378.523 1285.98 333.284 1331.59L126.622 1539.96C58.1839 1608.96 58.1839 1720.24 126.622 1789.24L290.869 1954.84C359.507 2024.05 359.277 2135.72 290.355 2204.64L128.158 2366.84C59.0351 2435.96 59.0351 2548.04 128.158 2617.16L415 2904"
            stroke="rgba(184, 228, 189, 1)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeDasharray={pathLength} // Total length of the black path
            strokeDashoffset={pathLength - dashOffset} // Reveal the black path **ahead** of the circle
          />

          {circlePosition && (
            <motion.image
              href="/static/WorkFlow/dot.png"
              width="60"
              height="60"
              x={circlePosition.x - 30}
              y={circlePosition.y - 30}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            />
          )}
        </svg>
      </div>
    </motion.div>
  );
};

export default LineAnimation;
