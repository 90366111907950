import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Footer.module.scss';
import { TextStyles, colors } from '../styles/index';
import { ChatIcon1 } from '../styles/core/icons';
import useIsMobile from '../hooks/useIsMobile';
import useBottomObserver from '../hooks/useBottomObserver';
import { handleSmoothScroll } from '../helpers/navigationHelpers';
import '../styles/effects/underline.scss';

const mainLinks = [
  { label: 'About', sectionId: 'about' },
  { label: 'Workflow', sectionId: 'workflow' },
  { label: 'Testimonials', sectionId: 'testimonials' },
  { label: 'Contact', sectionId: 'contact' },
];

const socialLinks = [
  { label: 'Instagram', href: 'https://www.instagram.com' },
  { label: 'Twitter', href: 'https://www.twitter.com' },
  { label: 'LinkedIn', href: 'https://www.linkedin.com' },
  { label: 'Gmail', href: 'mailto:someone@example.com' },
  { label: 'Contact', href: '/contact' },
];

const desktopSocialLinks = [
  { label: 'Instagram', href: 'https://www.instagram.com' },
  { label: 'Facebook', href: 'https://www.facebook.com' },
  { label: 'Phone', href: 'tel:+1234567890' },
  { label: 'Fax', href: 'fax:+1234567890' },
  { label: 'Gmail', href: 'mailto:someone@example.com' },
];

const Footer = ({ mode }) => {
  const isMobile = useIsMobile();
  const MainText = isMobile ? TextStyles.H3 : TextStyles.H1;
  const SocialText = TextStyles.UnderlinedMedium14;
  const isBottom = useBottomObserver(50); // Only make footer visible when near bottom
  const ArcType = mode === 'light' ? '/static/Footer/footer_arc_white.svg' : '/static/Footer/footer_arc_purple.svg';
  const navigate = useNavigate();
  const location = useLocation();

  const handleFooterLinkClick = (sectionId) => {
    handleSmoothScroll(navigate, location, sectionId, true);
  };

  return (
    <div className={`${styles.footerContainer} ${isBottom ? styles.visible : ''}`}>
      <div className={styles.svgContainer}>
        <img src={ArcType} alt="Top Arch" className="w-full h-auto" />
      </div>

      <div className="w-full h-[550px] md:h-[800px] flex flex-col justify-between text-dark-blue pt-12 md:pt-56">
        <div className="pt-8 flex items-center flex-col">
          <MainText color={colors.darkBlue}> Eden, the garden </MainText>
          <MainText color={colors.darkBlue}> that tends itself </MainText>
        </div>

        <div className={`flex ${isMobile ? 'flex-row gap-x-4 mt-4' : 'flex-col items-center'} w-full`}>
          {isMobile ? (
            <div className="flex flex-row w-full gap-x-4">
              <div className="flex flex-col w-1/2 gap-y-2 pl-6">
                {mainLinks.map((link, index) => (
                  <a
                    key={index}
                    onClick={() => handleFooterLinkClick(link.sectionId)}
                    className="flex items-center cursor-pointer"
                  >
                    <TextStyles.TextRegular20 className="hoverUnderline dark-link" color={colors.darkBlue}>
                      {link.label}
                    </TextStyles.TextRegular20>
                  </a>
                ))}
              </div>
              <div className="flex flex-col items-start w-1/2 gap-y-2 pl-6">
                {socialLinks.map((socialLink, index) => (
                  <a key={index} href={socialLink.href} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                    <SocialText>{socialLink.label}</SocialText>
                  </a>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-x-4 pt-12">
              {mainLinks.map((link, index) => (
                <React.Fragment key={index}>
                  <a
                    onClick={() => handleFooterLinkClick(link.sectionId)}
                    className="flex items-center cursor-pointer"
                  >
                    <TextStyles.TextRegular20 className="hoverUnderline dark-link" color={colors.darkBlue}>
                      {link.label}
                    </TextStyles.TextRegular20>
                  </a>
                  {index < mainLinks.length - 1 && <TextStyles.Dot color={colors.darkBlue} />}
                </React.Fragment>
              ))}
              <a href="/contact">
                <ChatIcon1 svgColor={colors.darkBlue} svgSize="18px" bgColor={colors.grey30} bgSize="9px" className="ml-4" />
              </a>
            </div>
          )}
        </div>

        {!isMobile && (
          <div className="relative flex items-center w-full max-w-[1280px] mx-auto">
            <div className="flex-1 text-left pl-4">
              <TextStyles.TextMedium14 color={colors.grey137}>
                © 2024 Eden, Inc. All rights reserved.
              </TextStyles.TextMedium14>
            </div>
            <div className="absolute left-1/2 transform -translate-x-1/2 flex gap-x-4">
              {desktopSocialLinks.map((socialLink, index) => (
                <a key={index} href={socialLink.href} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                  <SocialText>{socialLink.label}</SocialText>
                </a>
              ))}
            </div>
            <div className="flex-1 flex justify-end">
              <img src="/static/Nav/logo_text_dark.png" alt="Logo" className="w-[128px] p-4 h-auto" />
            </div>
          </div>
        )}
        {isMobile && (
          <div className="flex flex-col items-center mt-4">
            <img src="/static/Nav/logo_text_dark.png" alt="Logo" className="w-[128px] h-auto pb-4" />
            <TextStyles.TextMedium14 className="pb-6">© 2024 Eden, Inc. All rights reserved.</TextStyles.TextMedium14>
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
