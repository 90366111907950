import React, { useRef, useState, useEffect } from 'react';
import { TextStyles, colors } from '../../styles/index';
import { navStylesConfig } from '../../config/navStylesConfig';
import HoverButtonOne from '../../styles/buttons/HoverButtonOne'; 
import styles from './MobileMenu.module.scss';
import { ChatIcon1 } from '../../styles/core/icons'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { handleMenuCloseAfterNavigation, useOutsideClickHandler } from '../../helpers/navigationHelpers'; // Use helpers
import useIsMobile from '../../hooks/useIsMobile';

const MobileMenu = ({ onClose, mode = 'light', svgColor = colors.darkBlue }) => {
  const isMobile = useIsMobile(550); // Use the custom hook to check if the screen is mobile
  const MenuItem = isMobile ? TextStyles.H5 : TextStyles.H3;
  const { menuBackgroundColor, menuTextColor, mobileButtonHover, mobileButtonText, chatSVGColor } = navStylesConfig[mode];
  const menuRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Open menu when component mounts
  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Handle outside click for closing the menu
  useOutsideClickHandler(menuRef, () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsVisible(false);
      setIsClosing(false);
      onClose();
    }, 300);
  });

  return (
    <div
      ref={menuRef}
      className={`${styles['menu-animate']} ${isVisible ? styles['menu-open'] : isClosing ? styles['menu-close'] : ''}`}
      style={{
        backgroundColor: menuBackgroundColor,
      }}
    >
      {/* Navigation Links */}
      <MenuItem
        color={menuTextColor}
        className="pb-8 pt-28 pl-4"
        onClick={() => handleMenuCloseAfterNavigation(navigate, location, 'about', true, 0, onClose)} // Smooth scroll to About
      >
        About
      </MenuItem>
      <MenuItem
        color={menuTextColor}
        className="pb-8 pl-4"
        onClick={() => handleMenuCloseAfterNavigation(navigate, location, 'workflow', true, 0, onClose)} // Smooth scroll to Workflow
      >
        Workflow
      </MenuItem>
      <MenuItem
        color={menuTextColor}
        className="pb-8 pl-4"
        onClick={() => handleMenuCloseAfterNavigation(navigate, location, 'testimonials', true, 0, onClose)} // Smooth scroll to Testimonials
      >
        Testimonials
      </MenuItem>
      <MenuItem
        color={menuTextColor}
        className="pb-8 pl-4"
        onClick={() => handleMenuCloseAfterNavigation(navigate, location, 'contact', false, -1, onClose)} // Instant scroll to Contact with 10px offset
      >
        Contact
      </MenuItem>

      {/* Button to Contact Us */}
      <div className="w-full flex justify-center p-4">
        <HoverButtonOne href='/contact' bgColor={menuTextColor} width="100%" svgBG={colors.darkBlue} hoverColor={mobileButtonHover}>
          <TextStyles.TextMedium14 color={mobileButtonText}>Contact Us</TextStyles.TextMedium14>
          <ChatIcon1
            svgColor={colors.darkBlue}
            svgSize="18px"
            bgColor={chatSVGColor}
            bgSize="9px"
            className="ml-4"
          />
        </HoverButtonOne>
      </div>

      {/* Footer Links */}
      <div
        className="absolute left-0 right-0 flex flex-col items-start px-4 py-2 space-y-4"
        style={{ bottom: '16px' }}
      >
        <div className="flex items-center">
          <TextStyles.UnderlinedMedium14 color={menuTextColor}>Instagram</TextStyles.UnderlinedMedium14>
          <TextStyles.Dot color={colors.white} className="mx-2" />
          <TextStyles.UnderlinedMedium14 color={menuTextColor}>Twitter</TextStyles.UnderlinedMedium14>
          <TextStyles.Dot color={colors.white} className="mx-2" />
          <TextStyles.UnderlinedMedium14 color={menuTextColor}>LinkedIn</TextStyles.UnderlinedMedium14>
        </div>
        <div className="flex items-center">
          <TextStyles.UnderlinedMedium14 color={menuTextColor}>evan@edeonearth.ai</TextStyles.UnderlinedMedium14>
          <TextStyles.Dot color={colors.white} className="mx-2" />
          <TextStyles.UnderlinedMedium14 color={menuTextColor}>(732) 477-4149</TextStyles.UnderlinedMedium14>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
