import React from 'react';
import useNavScroll from '../../hooks/useNavScroll';
import useIsMobile from '../../hooks/useIsMobile';
import MainView from './MainView';
import ScrollView from './ScrollView';
import MobileNav from './MobileNav';
import styles from './Nav.module.scss';

const Nav = ({ mode = 'light' }) => {
  const { scrollState, hidden } = useNavScroll();
  const isMobile = useIsMobile();

  // Visibility style based on the hidden state, with smooth opacity transition
  const visibilityClass = hidden ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto';


  return (
    <div className={`${styles.navbar}  ${visibilityClass} transition-opacity duration-300 navbar`}>
      {isMobile ? (
        <MobileNav mode={mode} />
      ) : scrollState === 'main' ? (
        <MainView mode={mode} />
      ) : (
        <ScrollView mode={mode} />
      )}
    </div>
  );
};

export default Nav;
