import React, { useState } from 'react';
import { navStylesConfig } from '../../config/navStylesConfig';
import { CloseIcon1, DotsIcon1 } from '../../styles/core/icons'; // Import icons
import { colors, TextStyles } from '../../styles/index';
import MobileMenu from './MobileMenu';

const MobileNav = ({ mode = 'light', onNavClickAndScroll, setNavTriggeredScroll }) => { // Add props here
  const { mobileLogoColor, mobileTextColor } = navStylesConfig[mode];
  const logoSrc = mobileLogoColor === 'dark' ? '/static/Nav/logo_text_dark.png' : '/static/Nav/logo_text_light.png';
  const MenuText = TextStyles.TextMedium12;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleIconClick = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full flex justify-between items-center p-4 transition-all duration-300 z-50">
        <a href="/"><img src={logoSrc} alt="Logo" className="w-[95px] h-auto" /></a>
        <div className="flex items-center justify-center">
          <MenuText color={mobileTextColor} className="mr-4">
            Menu
          </MenuText>
          <div
            onClick={handleIconClick}
            aria-label={isMenuOpen ? 'Close Menu' : 'Open Menu'}
            role="button"
            tabIndex="0"
          >
            {isMenuOpen ? (
              <CloseIcon1 bgColor={colors.white25} bgSize="11px" svgSize="14px" className="ml-4" svgColor={mobileTextColor} />
            ) : (
              <DotsIcon1 bgColor={colors.white25} bgSize="9px" svgSize="18px" className="ml-4" svgColor={mobileTextColor} />
            )}
          </div>
        </div>
      </div>

      {/* Pass onNavClickAndScroll and setNavTriggeredScroll to MobileMenu */}
      {isMenuOpen && (
        <MobileMenu
          onClose={handleCloseMenu}
          mode={mode}
          onNavClickAndScroll={onNavClickAndScroll} // Pass the function
          setNavTriggeredScroll={setNavTriggeredScroll} // Pass the state setter
        />
      )}
    </>
  );
};

export default MobileNav;
