import React, { useMemo } from 'react'
import styles from './TextAnimation.module.scss'

const useAnimatedText = (text, delayOffset = 0, highlightWords = []) => {
  const animatedText = useMemo(() => {
    const words = text.split(' ')
    return words.map((word, index) => {
      // check if the current word is in the highlightWords array
      const isHighlighted = highlightWords.includes(word)
      // check if the current word is the last word
      const isLastWord = index === words.length - 1
      return (
        <span
          key={index}
          className={styles.fadeInElement}
          style={{
            animationDelay: `${delayOffset + index * 0.04}s`,
            background: isHighlighted ? 'linear-gradient(180deg, #98C19D 0%, #C7C26B 100%)' : 'none',
            WebkitBackgroundClip: isHighlighted ? 'text' : 'initial',
            WebkitTextFillColor: isHighlighted ? 'transparent' : 'initial',
            color: !isHighlighted ? 'black' : 'transparent',
          }}>
          {word}
          {!isLastWord && '\u00A0'}
        </span>
      )
    })
  }, [text, delayOffset, highlightWords])

  return animatedText
}

export default useAnimatedText
