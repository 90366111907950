import React from 'react'

const LeftArrow = ({ width = 27, height = 14, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 16 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15 28L0.999999 14.5L15 0.999999'
      stroke='#010122'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default LeftArrow
