import React, { useEffect } from 'react'
import { colors, gradients } from '../styles/core/colors'
import styles from './ContactFormSubmitModal.module.scss'
import { TextStyles } from '../styles'
import HoverButtonOne from '../styles/buttons/HoverButtonOne'
import { CloseIcon1 } from '../styles/core/icons'


export const ContactFormSubmitModal = ({ setIsModalOpen, isModalOpen }) => {
    useEffect(() => {
        if (isModalOpen) {
            // Add class to body to disable scroll
            document.body.classList.add("overflow-hidden");
        } else {
            // Remove class from body when modal is closed
            document.body.classList.remove("overflow-hidden");
        }

        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, [isModalOpen]);

    if (!isModalOpen) return null;
    return (
        <div className={`fixed h-full w-full top-0 left-0 flex justify-center items-center ${styles.modalContainer}`}>

            <div className={`flex flex-col items-center text-center w-[335px] h-[394px] md:w-[356px] rounded-3xl p-8 ${styles.modalContent}`}>
                <img src='/static/Nav/logo_dark.png' alt="modal logo" className="w-[76px] h-auto" />
                <TextStyles.GradientH6 gradient={gradients.gradientTwo} className="inline !my-2.5">
                    Thank you!
                </TextStyles.GradientH6>
                <TextStyles.H5 className="inline" style={{ color: "#000000" }}>
                    Your message has been sent successfully
                </TextStyles.H5>
                <TextStyles.TextRegular16 className="inline !my-2.5" style={{ color: "#000000", lineHeight: "18.78px" }}>
                    We will contact you shortly to answer all your questions. Glad you chose Eden!
                </TextStyles.TextRegular16>
                <div className="w-[271px] h-[56px] md:w-[292px]">
                    <HoverButtonOne bgColor={colors.darkBlue} width="100%" svgBG={colors.white} hoverColor={colors.grey30} onClick={() => setIsModalOpen(false)} >
                        <TextStyles.TextRegular16 color={colors.white}>Close</TextStyles.TextRegular16>
                        <CloseIcon1
                            svgColor={colors.darkBlue}
                            svgSize="15px"
                            bgColor={colors.grey30}
                            bgSize="9px"
                            className="ml-2.5"
                        />
                    </HoverButtonOne>
                </div>
            </div>
        </div>
    )
}
