import React, { useRef, useState } from 'react'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'
import useAnimatedText from '../../hooks/useAnimatedText'

const EdenWorkflow = () => {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  // use useIntersectionObserver Hook to detect when the component is in view
  useIntersectionObserver(containerRef, setIsVisible, 0.5)

  // set the words you want to highlight
  const highlightWordsDesktop = [
    'From',
    'patient',
    'intake',
    'to',
    'triage',
    'requests',
    'to',
    'digital',
    '&',
    'physical',
    'forms',
  ]
  const highlightWordsMobile = ['Eden’s', '7', 'Steps']

  // use useAnimatedText Hook to animate the text
  const animatedTextDesktop = useAnimatedText('From patient intake to triage', 0, highlightWordsDesktop)
  const animatedTextDesktop2 = useAnimatedText('requests to digital & physical forms', 0.4, highlightWordsDesktop)
  const animatedTextDesktop3 = useAnimatedText('Eden automates every manual', 0.8)
  const animatedTextDesktop4 = useAnimatedText('workflow within HIPAA reason', 1.2)
  const animatedTextMobile = useAnimatedText('Eden’s 7 Steps', 0, highlightWordsMobile)
  const animatedTextMobile2 = useAnimatedText('to Maximize Profit', 0.4)

  return (
    <div ref={containerRef} className='flex justify-center items-center bg-white relative'>
      <div className='hidden lg:block w-full h-[542px] relative'>
        <div className='flex flex-col justify-center items-center h-[195px] mx-auto'>
          <div>
            {isVisible && (
              <div className='text-center font-raleway text-[32px] h-[76px] font-light leading-[37.57px]'>
                {animatedTextDesktop}
                <br />
                {animatedTextDesktop2}
              </div>
            )}
          </div>
          <div className='text-center font-raleway h-[96px] text-5xl font-light leading-[48px] mt-[23px]'>
            {isVisible && (
              <div>
                {animatedTextDesktop3}
                <br />
                {animatedTextDesktop4}
              </div>
            )}
          </div>
        </div>
        <img src='/static/About/Bottom.png' alt='About' className='absolute bottom-0 left-0 w-full h-[303px]' />
      </div>
      <div className='block lg:hidden w-full h-[293px] relative'>
        <div className='w-[343px] h-[63px] flex flex-col justify-center mx-auto'>
          <div className='text-center font-raleway text-[42px] font-light leading-[49.31px]'>
            {isVisible && <span>{animatedTextMobile}</span>}
          </div>
          <div className='text-center font-raleway text-[42px] font-light leading-[49.31px] mt-2'>
            {isVisible && animatedTextMobile2}
          </div>
        </div>
        <img src='/static/About/MobileBottom.png' alt='About' className='absolute bottom-0 left-0 w-full h-[58.07px]' />
      </div>
    </div>
  )
}

export default EdenWorkflow
