import React, { useRef, useState, useEffect } from 'react';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import useAnimatedText from '../../hooks/useAnimatedText';

const EdenDescription = () => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024); // Set initial state based on screen size

  // Detect screen size on resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // use useIntersectionObserver Hook to detect when the component is in view
  useIntersectionObserver(containerRef, setIsVisible, 0.5);

  // Set the words to highlight
  const highlightWords1 = ['Eden', 'is', 'a', 'revolutionary', 'platform'];

  // Use useAnimatedText Hook to animate the text
  const animatedTextDesktop = useAnimatedText('Eden is a revolutionary platform', 0, highlightWords1);
  const animatedTextDesktop2 = useAnimatedText(
    'that combines artificial intelligence, hospitality principles, and nature’s soft touch, for a harmonious healthcare ecosystem.',
    0.5,
  );
  const animatedTextMobile = useAnimatedText(
    'that combines artificial intelligence and nurture principles from nature for a harmonious medical environment.',
    0.5,
  );

  return (
    <div ref={containerRef} className='flex justify-center lg:ms-[23vw]'>
      <div
        className={`h-[198px] lg:h-60 font-light text-[28px] lg:text-5xl leading-[32.87px] lg:leading-[48px] w-[335px] lg:w-[843px]`}>
        <div className='md:inline-block z-10'>
          {isVisible && <span>{animatedTextDesktop}</span>}
          {isMobile ? (
            <span className='text-black'>{isVisible && <span>&nbsp;{animatedTextMobile}</span>}</span>
          ) : (
            <span className='hidden lg:block'>{isVisible && <span>{animatedTextDesktop2}</span>}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default EdenDescription;
