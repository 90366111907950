import React from 'react';
import { TextStyles, colors } from '../../styles/index';
import useIsMobile from '../../hooks/useIsMobile'; // Adjust the path based on your file structure
import { ReactComponent as IconDown } from '../../assets/icons/down_arrow.svg';
import styles from'./Home.module.scss';

export const Home = () => {
  const isMobile = useIsMobile(); // Use the custom hook to check if the screen is mobile

  // Determine which TextStyle component to use
  const ShadowText = isMobile ? TextStyles.H3Shadow : TextStyles.H1Shadow;
  const Text = isMobile ? TextStyles.H3 : TextStyles.H1;
  const SubText = isMobile ? TextStyles.TextRegular16 : TextStyles.TextRegular18;

  return (
    <div className="bg-dark-blue text-white w-full h-[90vh] flex flex-col items-center justify-center relative">
      {/* Centered main content */}
      <div className="flex flex-col justify-center items-center text-center">
        <ShadowText color={colors.white} className="max-w-full flex flex-row">
          <p className = {styles.fade1}>The&nbsp;</p>
          <p className = {styles.fade2}>3-second&nbsp;</p>
          <p className = {styles.fade3}>Answer</p>
        </ShadowText>
        <Text
          color={colors.white70}
          className="w-[300px] md:w-[800px] pt-1 h-auto overflow-wrap flex flex-row flex-wrap break-word justify-center"
        >
          <p className = {styles.fade4}>to&nbsp;</p>
          <p className = {styles.fade5}>Healthcares&nbsp;</p>
          <p className = {styles.fade6}>Burnout&nbsp;</p>
          <p className = {styles.fade7}>Problem</p>

          </Text>
        <SubText
          color={colors.white}
          className="w-[325px] md:w-[425px] pt-8 h-auto overflow-wrap flex flex-row flex-wrap break-word justify-center"
        >
          <p className={styles.fade7_1}>A&nbsp;</p>
          <p className={styles.fade7_2}>modern&nbsp;</p>
          <p className={styles.fade7_3}>POS&nbsp;</p>
          <p className={styles.fade7_4}>system&nbsp;</p>
          <p className={styles.fade7_5}>for&nbsp;</p>
          <p className={styles.fade7_6}>reducing&nbsp;</p>
          <p className={styles.fade7_7}>admin&nbsp;</p>
          <p className={styles.fade7_8}>fatigue&nbsp;</p>
          <p className={styles.fade7_9}>and&nbsp;</p>
          <p className={styles.fade7_10}>maximizing&nbsp;</p>
          <p className={styles.fade7_11}>patient&nbsp;</p>
          <p className={styles.fade7_12}>volume.&nbsp;</p>
          <p className={styles.fade7_13}>HIPAA&nbsp;</p>
          <p className={styles.fade7_14}>compliant.</p>
        </SubText>
      </div>

      {/* Bottom content fixed at the bottom */}
      <div className="absolute bottom-12 md:bottom-6 flex flex-col items-center">
        <TextStyles.TextRegular12
          color={colors.white70}
          className="text-center pt-[75px] md:pt-[150px] pb-4"
        >
          <p className={styles.fade8}>Feel The Harmony</p>
        </TextStyles.TextRegular12>
        <IconDown className={`${styles.fade9} ${styles.svgGray} w-[15px] md:w-[20px] h-auto`} />

      </div>
    </div>
  );
};
