import React, { useEffect, useRef } from 'react'
import styles from './PartnersCarousel.module.scss'

const PartnersCarousel = () => {
  const logos = [
    '/static/PartnersLogo/HarvardLogo.png',
    '/static/PartnersLogo/EpicLogo.png',
    '/static/PartnersLogo/AthenahealthLogo.png',
    '/static/PartnersLogo/CernerLogo.png',
    '/static/PartnersLogo/ElationLogo.png',
  ]

  const tripleLogos = [...logos, ...logos, ...logos]

  const scrollingContainerRef = useRef(null)
  const scrollingContainerReverseRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY
      const speed = 0.2

      if (scrollingContainerRef.current) {
        scrollingContainerRef.current.style.transform = `translateX(${-scrollY * speed}px)`
      }

      if (scrollingContainerReverseRef.current) {
        scrollingContainerReverseRef.current.style.transform = `translateX(${scrollY * speed}px)`
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className='relative overflow-hidden bg-white lg:h-[350px] h-[144.1px]'>
      <div className={`${styles.ScrollingContainer} h-1/2 flex items-center`}>
        {tripleLogos.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`${src.split('/').pop().split('.')[0]}`}
            className='h-8 lg:h-16 mx-5 lg:mx-10 px-7 lg:px-10'
          />
        ))}
      </div>
      <div className={`${styles.ScrollingContainerReverse} h-1/2 flex items-center justify-start`}>
        {tripleLogos.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`${src.split('/').pop().split('.')[0]}`}
            className='h-8 lg:h-16 mx-5 lg:mx-10 px-7 lg:px-10'
          />
        ))}
      </div>
    </div>
  )

  // return (
  //   <div className='relative overflow-hidden bg-white lg:h-[350px] h-[144.1px]'>
  //     <div ref={scrollingContainerRef} className='h-1/2 flex items-center'>
  //       {tripleLogos.map((src, index) => (
  //         <img
  //           key={index}
  //           src={src}
  //           alt={`${src.split('/').pop().split('.')[0]}`}
  //           className='lg:h-16 h-8 lg:mx-10 mx-5 lg:px-10 px-5'
  //         />
  //       ))}
  //     </div>
  //     <div ref={scrollingContainerReverseRef} className='h-1/2 flex items-center justify-end'>
  //       {tripleLogos.map((src, index) => (
  //         <img
  //           key={index}
  //           src={src}
  //           alt={`${src.split('/').pop().split('.')[0]}`}
  //           className='lg:h-16 h-8 lg:mx-10 mx-5 lg:px-10 px-5'
  //         />
  //       ))}
  //     </div>
  //   </div>
  // )
}

export default PartnersCarousel
