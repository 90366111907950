// useIsMobile.js
import { useState, useEffect } from 'react';

const useIsMobile = (threshold = 768) => {
  const [isBelowThreshold, setIsBelowThreshold] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsBelowThreshold(window.innerWidth <= threshold);
    };

    // Initial check
    handleResize();

    // Add event listener to handle screen resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [threshold]);

  return isBelowThreshold;
};

export default useIsMobile;
