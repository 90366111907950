import React, { useState, useRef } from 'react'

const PasswordEntry = ({ onCorrectPassword, centerWorkflow, onDigitEntered }) => {
  const [password, setPassword] = useState(['', '', '', '', ''])
  const inputRefs = useRef([])
  const correctPassword = '32323'

  // Handle input changes
  const handleChange = (value, index) => {
    if (value.length > 1) return // Restrict to single characters
    const newPassword = [...password]
    newPassword[index] = value
    setPassword(newPassword)
    onDigitEntered() // Call onDigitEntered for visual effects

    // Move focus to the next input box
    if (index < 4 && value !== '') {
      inputRefs.current[index + 1].focus()
    }

    // Check if the password is complete
    if (newPassword.every((digit) => digit !== '')) {
      if (newPassword.join('') === correctPassword) {
        onCorrectPassword() // Trigger workflow start on correct password
      } else {
        resetPassword() // Reset fields on incorrect password
      }
    }
  }

  // Reset the password fields
  const resetPassword = () => {
    setPassword(['', '', '', '', ''])
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus() // Reset focus to the first input
    }
  }

  // Handle click to center workflow and focus on the first input
  const handleClick = () => {
    centerWorkflow()
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus()
    }
  }

  return (
    <div className='flex gap-2' onClick={handleClick}>
      {password.map((_, index) => (
        <input
          key={index}
          type='text'
          inputMode='numeric' // Use numeric keyboard on mobile
          value={password[index]}
          onChange={(e) => handleChange(e.target.value, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          className='w-12 h-12 text-center text-xl border rounded-2xl focus:outline-none'
          maxLength={1}
        />
      ))}
    </div>
  )
}

export default PasswordEntry
