import { useState, useEffect } from 'react';

const useNavScroll = () => {
  const [scrollState, setScrollState] = useState('main'); // 'main' for MainView, 'scroll' for ScrollView
  const [hidden, setHidden] = useState(false); // State to track navbar visibility
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Update scroll state based on position
      if (currentScrollY > 200) {
        setScrollState('scroll');
      } else {
        setScrollState('main');
      }

      // Toggle visibility based on scroll direction with a 50px threshold
      if (Math.abs(currentScrollY - lastScrollY) > 50) {
        setHidden(currentScrollY > lastScrollY);
        setLastScrollY(currentScrollY);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return { scrollState, hidden };
};

export default useNavScroll;
