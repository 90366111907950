import React from 'react'
import { motion } from 'framer-motion'

const pageMessage = [
  {
    title: 'Appointment',
    description: 'Appointment Scheduling',
    todays: '18',
    cost: '1,400',
  },
  {
    title: 'Registration',
    description: 'Patient Registration & Intake',
    todays: '23',
    cost: '2,400',
  },
  {
    title: 'Insurance',
    description: 'Insurance Verification',
    todays: '21',
    cost: '49',
  },
  {
    title: 'Medical Records',
    description: 'Medical Records',
    todays: '24',
    cost: '287',
  },
  {
    title: 'Patient Communication',
    description: 'Patient Communication',
    todays: '24',
    cost: '287',
  },
  {
    title: 'Patient Correspondence',
    description: 'Patient Correspondence',
    todays: '17',
    cost: '287',
  },
  {
    title: 'Billing',
    description: 'Billing',
    todays: '23',
    cost: '34',
  },
]

const specialMessage = [
  {
    left: '<',
    right: 'min',
  },
]

const Task = ({ currentIndex, isVisible }) => {
  // define framer-motion variants for fade-in and fade-out
  const variants = {
    hidden: { opacity: 0, y: -20 }, // move up and fade out
    visible: { opacity: 1, y: 0, transition: { duration: 1.5 } }, // fade in and move to position
    exit: { opacity: 0, y: -20, transition: { duration: 1.5 } }, // fade out and move up
  }

  const staggerChildren = {
    visible: {
      transition: {
        staggerChildren: 0.2, // stagger effect for children
      },
    },
    exit: {
      transition: {
        staggerChildren: 0.2, // stagger effect for exit animation
      },
    },
  }

  return (
    <motion.div
      className='flex flex-col w-[307px] h-[118px] lg:h-[227px] z-10'
      initial='hidden'
      animate={isVisible ? 'visible' : 'exit'} // Animate to visible if isVisible is true, else exit
      exit='exit'
      variants={staggerChildren}>
      {/* Task title and description */}
      <motion.div className='h-[103px]' variants={variants}>
        <div className='text-xs lg:text-base font-normal leading-[14.09px] lg:leading-[18.78px] text-white w-[28px] lg:w-[37px] h-[14px] lg:h-[19px]'>
          Task:
        </div>
        <div className='text-xl lg:text-[32px] font-medium leading-[23.48px] lg:leading-[37.57px] text-white w-[335px] lg:w-[307px] h-[23px] lg:h-[76px]'>
          {pageMessage[currentIndex].description}
        </div>
      </motion.div>

      {/* Task statistics for Todays and Cost */}
      <div className='h-[57px] lg:h-[100px] flex flex-row'>
        <motion.div
          className='flex flex-col justify-center items-center backdrop-blur-[294.5px] bg-white/10 w-[109px] h-[57px] lg:h-[100px] rounded-[24px]'
          variants={variants}>
          <div className='text-xs lg:text-base font-normal leading-[14.09px] lg:leading-[18.78px] text-white'>
            Todays:
          </div>
          <div className='text-2xl lg:text-[42px] font-normal leading-[28.12px] lg:leading-[49.31px] text-white'>
            <div className='flex flex-row'>
              {currentIndex === 2 ? (
                <div className='text-[10px] leading-[11.74px] lg:leading-[14.09px] lg:text-xs mt-2 lg:mt-3 me-2'>
                  {specialMessage[0].left}
                </div>
              ) : null}
              {pageMessage[currentIndex].todays}
              {currentIndex === 2 ? (
                <div className='text-[10px] leading-[11.74px] lg:leading-[14.09px] lg:text-xs mt-1 lg:mt-2 ms-1 lg:ms-2'>
                  {specialMessage[0].right}
                </div>
              ) : null}
            </div>
          </div>
        </motion.div>

        <motion.div
          className='flex flex-col justify-center items-center backdrop-blur-[294.5px] bg-white/10 w-[184px] h-[57px] lg:h-[100px] rounded-[24px] ms-[10px]'
          variants={variants}>
          <div className='text-xs lg:text-base font-normal leading-[14.09px] lg:leading-[18.78px] text-white'>
            Cost:
          </div>
          <div className='text-2xl lg:text-[42px] font-normal leading-[28.12px] lg:leading-[49.31px] text-white'>
            {pageMessage[currentIndex].cost}$
          </div>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default Task
