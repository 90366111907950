import React, { useState, useEffect, useRef } from 'react'
import ConversationChange from '../components/TestimonialsComponents/ConversationChange'
import { colors } from '../styles/core/colors'
import styles from './Testimonials.module.scss'

const { lightGreen, lightYellow, greyTwo } = colors

const Testimonials = () => {
  const testimonialsRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isFadingOut, setIsFadingOut] = useState(false)
  const [hasStarted, setHasStarted] = useState(false)

  const goToNext = () => {
    setIsFadingOut(true)
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex === conversationsImages.length - 1 ? 0 : prevIndex + 1))
      setIsFadingOut(false)
    }, 500)
  }

  const goToPrev = () => {
    setIsFadingOut(true)
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? conversationsImages.length - 1 : prevIndex - 1))
      setIsFadingOut(false)
    }, 500)
  }

  const conversationsImages = [
    '/static/Testimonials/Conversation-1.png',
    '/static/Testimonials/Conversation-2.png',
    '/static/Testimonials/Conversation-3.png',
  ]

  const smoothScrollTo = (targetY, duration) => {
    const startY = window.scrollY
    const diff = targetY - startY
    let startTime = null

    const scroll = (currentTime) => {
      if (!startTime) startTime = currentTime
      const timeElapsed = currentTime - startTime
      const progress = Math.min(timeElapsed / duration, 1)

      window.scrollTo(0, startY + diff * progress)

      if (timeElapsed < duration) {
        requestAnimationFrame(scroll)
      }
    }
    requestAnimationFrame(scroll)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasStarted) {
            setHasStarted(true)
            smoothScrollTo(entry.target.offsetTop, 700)
          }
        })
      },
      { threshold: 0.2 },
    )

    if (testimonialsRef.current) {
      observer.observe(testimonialsRef.current)
    }

    return () => {
      if (testimonialsRef.current) {
        observer.unobserve(testimonialsRef.current)
      }
    }
  }, [hasStarted])

  return (
    <div
      ref={testimonialsRef}
      className={`h-[1092px] lg:h-screen flex flex-col relative overflow-hidden ${styles.testimonialBackground}`}
      style={{
        background:
          window.innerWidth >= 1024
            ? `radial-gradient(circle at center bottom, ${
                currentIndex === 0 ? lightGreen : currentIndex === 1 ? lightYellow : greyTwo
              } 0%, #ffffff 70%)`
            : `radial-gradient(ellipse at center bottom, ${currentIndex === 0 ? lightGreen : currentIndex === 1 ? lightYellow : greyTwo} 20%, #ffffff 70%, #ffffff 100%)`,
      }}>
      <img
        src='/static/About/MobileBottom.png'
        alt='About'
        className='absolute top-0 left-0 w-full h-[58px] rotate-180 block lg:hidden'
      />
      {/* <img
        src='/static/About/Bottom.png'
        alt='About'
        className='absolute top-0 left-0 w-full h-[150.5px] rotate-180 hidden lg:block'
      /> */}
      {hasStarted && (
        <div>
          <div className='flex flex-col h-[199px] lg:h-[224px]'>
            <div className='text-left font-normal ms-5 xl:ms-20 mt-[99px] lg:mt-40  text-[42px] xl:text-5xl leading-[49.31px] xl:leading-[56.35px] lg:leading-[42px]'>
              <div
                className={`${styles.gradientText} ${hasStarted ? styles['fade-in'] : ''} ${styles['fade-in-1']} h-[48px] xl:h-[56px] lg:h-[42px]`}>
                2300+
              </div>
              <div
                className={`${styles.gradientText} ${hasStarted ? styles['fade-in'] : ''} ${styles['fade-in-1']} h-[48px] xl:h-[56px] lg:h-[42px]`}>
                Conversations
              </div>
            </div>
            <div className='text-left font-normal text-black ms-5 xl:ms-20 text-[32px] xl:text-5xl leading-normal xl:leading-[56.35px] xl:h-[112px] lg:h-[70px]'>
              <div
                className={`${hasStarted ? styles['fade-in'] : ''} ${styles['fade-in-2']} h-[35px] xl:h-[56px] lg:h-[38px] mb-1 `}>
                with a Single
              </div>
              <div className={`${hasStarted ? styles['fade-in'] : ''} ${styles['fade-in-3']} h-[35px] xl:h-[56px] lg:h-[38px]`}>
                Complaint
              </div>
            </div>
          </div>
          <img
            src={conversationsImages[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
            className={`absolute bottom-0 left-[9vw] xl:left-[15vw] w-[80vw] md:w-[60vw] lg:w-[50vw] max-h-[30vh] min-h-[350px] xl:max-h-[70vh] lg:max-h-[45vh] 2xl:max-h-[85vh] object-contain h-auto ${
              isFadingOut ? styles['fade-out-right'] : hasStarted ? styles['fade-in-up'] : ''
            }`}
          />
          <div
            className={`lg:absolute md:right-[500px] lg:right-[500px] 2xl:right-[550px] md:bottom-0 md:h-[90%] ${
              isFadingOut ? styles['fade-out-right'] : hasStarted ? styles['fade-in-up'] : ''
            }`}>
            <ConversationChange goToPrev={goToPrev} goToNext={goToNext} currentIndex={currentIndex} />
          </div>{' '}
        </div>
      )}
    </div>
  )
}

export default Testimonials
