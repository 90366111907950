// src/styles/inputs/InputOne.js
import React from 'react';
import styled from 'styled-components';
import { colors } from '../core/colors'; // Ensure this path is correct
import { TextRegular16 } from '../core/textStyles';

const StyledInput = styled.input`
  padding: 10px 20px;
  background-color: ${colors.white241};
  border: 2px solid ${colors.white241};
  border-radius: 30px;
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;
  font-family: inherit;
    color: ${({ textColor }) => textColor || colors.darkBlue }; /* Dynamic text color */

  ${({ textStyle: TextStyle }) =>
    TextStyle &&
    `
      font-size: ${TextStyle.fontSize || '16px'};
      font-weight: ${TextStyle.fontWeight || 'normal'};
  `}

  &:hover {
    cursor: text; // Change to text edit icon on hover
  }

  &:focus {
    background-color: ${colors.white}; // Change background on focus
    border-color: ${colors.lightGreen}; // Change border on focus
  }

  &::placeholder {
    color: ${colors.darkBlue}; // Placeholder color
  }
`;

// InputOne component
const InputOne = ({ placeholder, textStyle: TextStyle, ...props }) => {
  return (
    <StyledInput
      placeholder={placeholder}
      textStyle={TextStyle || TextRegular16} // Default to TextRegular16 if no style is provided
      {...props}
    />
  );
};

export default InputOne;
