import React, { useState, useEffect } from 'react';
import Contact from '../modules/Contact';
import About from '../modules/About';
import WorkFlow from '../modules/WorkFlow/index';
import Testimonials from '../modules/Testimonials';
import { Home } from '../components/Home/Home';
import Footer from '../components/Footer';
import Hero from '../components/Hero/Hero';
import Nav from '../components/Nav/Nav';
import useSectionObserver from '../hooks/useSectionObserver';
import { PreLoader2 } from '../components/PreLoader/PreLoader2';

// List of all sections to observe for the landing page
const sections = ['home', 'hero', 'about', 'workflow', 'testimonials', 'contact', 'footer'];

const LandingPage = () => {
  const [loading, setLoading] = useState(true);
  const [disableNav, setDisableNav] = useState(false); // Track navbar visibility

  useEffect(() => {
    const hasSeenPreloader = sessionStorage.getItem('hasSeenPreloader');

    if (!hasSeenPreloader) {
      const timer = setTimeout(() => {
        setLoading(false);
        sessionStorage.setItem('hasSeenPreloader', 'true');
      }, 4000); // Adjust this time as needed

      return () => clearTimeout(timer);
    } else {
      setLoading(false);
    }
  }, []);

  const mode = useSectionObserver(loading || disableNav ? [] : sections); // Disable observer if loading or navbar is disabled

  if (loading) {
    return <PreLoader2 />;
  }

  return (
    <div>
      {!disableNav && <Nav mode={mode} />} {/* Conditionally render Nav based on disableNav state */}
      <section id='home'>
        <Home />
      </section>
      <section id='hero'>
        <Hero />
      </section>
      <section id='about'>
        <About />
      </section>
      <section id='workflow'>
        {/* Pass setDisableNav to WorkFlow to control navbar visibility */}
        <WorkFlow setDisableNav={setDisableNav} />
      </section>
      <section id='testimonials'>
        <Testimonials />
      </section>
      <section id='contact'>
        <Contact />
      </section>
      <section id='footer' className='bg-dark-blue w-full'>
        <Footer />
      </section>
    </div>
  );
};

export default LandingPage;
