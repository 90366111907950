import React, { useEffect, useRef, useState } from 'react';
import useIsMobile from '../hooks/useIsMobile';
import HoverButtonOne from '../styles/buttons/HoverButtonOne.js';
import styles from './Contact.module.scss';

const Contact = () => {
  const isMobile = useIsMobile(768);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);

          // Scroll manually to the Contact section without triggering the footer
          const contactPosition = sectionRef.current.getBoundingClientRect().top + window.scrollY;
          const offset = 1; // Adjust this offset as needed to avoid the footer observer
          window.scrollTo({ top: contactPosition - offset, behavior: 'smooth' });

          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      },
      {
        threshold: 0.2, // Trigger when 20% of the section is in view
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className="w-full h-screen flex justify-center items-center bg-[#010122] gap-12"
    >
      <div
        className="flex flex-col justify-center items-center w-[335px] lg:w-[480px] h-[298px] lg:h-[328px]"
        style={{ backgroundColor: '#010122' }}
      >
        <div className="flex flex-col items-center text-white w-full h-[194px] lg:h-[224px]">
          <div className="w-full text-center h-[105px] lg:h-[150px]">
            <h1 className="flex flex-row justify-center text-[42px] lg:text-[64px] font-raleway font-light text-white/70 leading-[49.31px] lg:leading-[75.14px] h-[49px] lg:h-[75px] m-0">
              <p className={isVisible ? styles.fade1 : ''}>This&nbsp;</p>
              <p className={isVisible ? styles.fade2 : ''}>is&nbsp;</p>
              <p className={isVisible ? styles.fade3 : ''}>your&nbsp;</p>
              <p className={isVisible ? styles.fade4 : ''}>Life</p>
            </h1>
            <h1
              className="flex flex-row justify-center text-[42px] lg:text-[64px] font-raleway font-light text-white leading-[49.31px] lg:leading-[75.14px] h-[49px] lg:h-[75px] mt-2 lg:m-0"
              style={{
                textShadow: '-3px 2px 25px 0px rgba(255, 255, 255, 0.6);',
              }}
            >
              <p className={isVisible ? styles.fade5 : ''}>Come&nbsp;</p>
              <p className={isVisible ? styles.fade6 : ''}>Alive&nbsp;</p>
              <p className={isVisible ? styles.fade7 : ''}>For&nbsp;</p>
              <p className={isVisible ? styles.fade7 : ''}>It</p>
            </h1>
          </div>
          <p className="flex flex-row flex-wrap break-word justify-center lg:w-[450px] w-[330px] text-center text-base lg:text-lg leading-[18.78px] lg:leading-[21.13px] text-[#f1f1f1] h-[57px] lg:h-[42px] w-[335px] lg:w-[428px] mt-8">
            <p className={isVisible ? styles.fade7_1 : ''}>We&nbsp;</p>
            <p className={isVisible ? styles.fade7_2 : ''}>are&nbsp;</p>
            <p className={isVisible ? styles.fade7_3 : ''}>always&nbsp;</p>
            <p className={isVisible ? styles.fade7_4 : ''}>ready&nbsp;</p>
            <p className={isVisible ? styles.fade7_5 : ''}>to&nbsp;</p>
            <p className={isVisible ? styles.fade7_6 : ''}>answer&nbsp;</p>
            <p className={isVisible ? styles.fade7_7 : ''}>your&nbsp;</p>
            <p className={isVisible ? styles.fade7_8 : ''}>questions&nbsp;</p>
            <p className={isVisible ? styles.fade7_9 : ''}>and&nbsp;</p>
            <p className={isVisible ? styles.fade7_10 : ''}>provide&nbsp;</p>
            <p className={isVisible ? styles.fade7_11 : ''}>additional&nbsp;</p>
            <p className={isVisible ? styles.fade7_12 : ''}>information&nbsp;</p>
            <p className={isVisible ? styles.fade7_13 : ''}>about&nbsp;</p>
            <p className={isVisible ? styles.fade7_14 : ''}>our&nbsp;</p>
            <p className={isVisible ? styles.fade7_15 : ''}>product.</p>
          </p>
        </div>
        <HoverButtonOne
          className="flex justify-center items-center mt-12 rounded-[75px]"
          bgColor="white"
          textColor="#010122"
          hoverColor="rgba(16, 16, 16, 0.1)"
          width={isMobile ? '235px' : '350px'}
        >
          <div className="flex flex-row justify-center items-center gap-4">
            <span className="text-sm text-[#010122]">Contact Us</span>
            <img className="w-8 h-8" src="/static/Contact/Contact.png" alt="Contact" />
          </div>
        </HoverButtonOne>
      </div>
    </div>
  );
};

export default Contact;
