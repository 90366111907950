import React from 'react';
import { TextStyles } from '../../styles/index';
import { ReactComponent as ChatIcon } from '../../assets/icons/chat_icon.svg'; 
import { navStylesConfig } from '../../config/navStylesConfig'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { handleSmoothScroll } from '../../helpers/navigationHelpers'; // Use helper for smooth scrolling with react-scroll
import '../../styles/effects/underline.scss'
import {colors} from '../../styles/index'

const MainView = ({ mode }) => {
  const {
    logoColor,
    textColor,
    dotColor,
    svgColor,
    backgroundColor
  } = navStylesConfig[mode];
  
  const logo = logoColor === 'dark' ? '/static/Nav/logo_text_dark.png' : '/static/Nav/logo_text_light.png';
  const NavText = TextStyles.TextMedium14;

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="fixed left-0 top-8 w-full flex justify-between items-center pl-16 pr-16 transition-all duration-300 z-10">
      <a href="/">
        <img src={logo} alt="Logo" className="w-[130px] h-auto" />
      </a>
      <nav className="flex items-center space-x-4 rounded-full px-4 py-3" style={{ backgroundColor: colors.grey15 }}>
        <button onClick={() => handleSmoothScroll(navigate, location, 'about', true)} className="flex items-center">
          <NavText color={textColor} className = 'hoverUnderline light-link'>About</NavText>
        </button>
        <TextStyles.Dot color={dotColor} />
        <button onClick={() => handleSmoothScroll(navigate, location, 'workflow', true)} className="flex items-center">
          <NavText color={textColor} className = 'hoverUnderline light-link'>Workflow</NavText>
        </button>
        <TextStyles.Dot color={dotColor} />
        <button onClick={() => handleSmoothScroll(navigate, location, 'testimonials', true)} className="flex items-center">
          <NavText color={textColor} className = 'hoverUnderline light-link'>Testimonials</NavText>
        </button>
        <TextStyles.Dot color={dotColor} />
        {/* Use instant scroll for Contact section with 10px offset */}
        <button onClick={() => handleSmoothScroll(navigate, location, 'contact', false, -1)} className="flex items-center">
          <NavText color={textColor} className = 'hoverUnderline light-link'>Contact</NavText>
        </button>
        <a href="/contact">
          <div className="flex items-center justify-center w-[48px] h-[34px] rounded-full ml-2" style={{ backgroundColor, color: svgColor }}>
            <ChatIcon className="w-[20px] h-auto icon" />
          </div>
        </a>
      </nav>
    </div>
  );
};

export default MainView;
