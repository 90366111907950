// styles/core/colors.js - hex for all  used colors
export const colors = {
  darkBlue: '#010122',
  greyOne: '#8D8D8D',
  greyTwo: '#E0E0E0',
  greyThree: '#F1F1F1',
  white: '#FFFFFF',
  lightGreen: '#B8E4BD',
  lightYellow: '#E6E7BE',
  sysSuccess: '#54A73F',
  sysError: '#DD6666',
  grey30: '#FFFFFF4D',
  grey60: 'rgba(255, 255, 255, 0.6)',
  grey70: 'rgba(255, 255, 255, 0.7)',
  purple60: 'rgba(1, 1, 34, 0.6)',
  grey15: 'rgba(182, 182, 182, 0.15)',
  grey5: 'rgba(182, 182, 182, 0.05)',
  grey10: 'rgba(182, 182, 182, 0.10)',
  white25: 'rgba(255, 255, 255, 0.25)',
  purple15: 'rgba(1, 1, 34, 0.1)',
  grey137: 'rgba(137, 137, 137, 1)',
  grey141: 'rgba(141, 141, 141, 1)',
  white241: 'rgba(241, 241, 241, 1)',
  purple10: 'rgba(1, 1, 34, 0.10)',
  purple40: 'rgba(1, 1, 34, 0.4)',
  gray30: 'rgba(255, 255, 255, 0.3)',
  white70: 'rgba(255, 255, 255, 0.7)',
}

export const gradients = {
  gradientOne: 'linear-gradient(180deg, #B7E4BD 0%, #E9E7BE 100%)',
  gradientTwo: 'linear-gradient(180deg, #98C19D 0%, #C7C26B 100%);',
}
