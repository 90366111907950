import React from 'react'

const RightArrow = ({ width = 27, height = 14, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 16 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path d='M1 0.999999L15 14.5L1 28' stroke='#010122' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export default RightArrow
