// styles/core/textStyles.js
import styled from 'styled-components'
import { textColors } from './theme'
import { gradients } from './colors'

export const Text = styled.p`
  font-family: 'Raleway', sans-serif;
  color: ${({ color }) => color || textColors.primary};
  background-color: ${({ background }) => background || 'transparent'};
  margin: 0;
`
export const H1 = styled(Text)`
  font-size: 64px;
  line-height: 75px;
  font-weight: 400;
`

export const H2 = styled(Text)`
  font-size: 42px;
  line-height: 49px;
  font-weight: 400;
`

export const H3 = styled(Text)`
  font-size: 32px;
  line-height: 37px;
  font-weight: 400;
`

export const H4 = styled(Text)`
  font-size: 28px;
  line-height: 33px;
  font-weight: 400;
`

export const H5 = styled(Text)`
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
`

export const H6 = styled(Text)`
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;
`
export const H1Shadow = styled(H1)`
  text-shadow: -3px 2px 25px rgba(255, 255, 255, 0.6);
`;

export const H2Shadow = styled(H2)`
  text-shadow: -3px 2px 25px rgba(255, 255, 255, 0.6);
`;

export const H3Shadow = styled(H3)`
  text-shadow: -3px 2px 25px rgba(255, 255, 255, 0.6);
`;
// Font styles

export const TextRegular20 = styled(Text)`
  font-size: 20px;
  font-weight: 400;
`

export const TextMedium20 = styled(Text)`
  font-size: 20px;
  font-weight: 500;
`

export const TextRegular18 = styled(Text)`
  font-size: 18px;
  font-weight: 400;
`

export const TextMedium18 = styled(Text)`
  font-size: 18px;
  font-weight: 500;
`

export const TextRegular16 = styled(Text)`
  font-size: 16px;
  font-weight: 400;
`

export const TextMedium16 = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`

export const TextMedium14 = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`

export const TextMedium12 = styled(Text)`
  font-size: 12px;
  font-weight: 500;
`

export const TextRegular14 = styled(Text)`
  font-size: 14px;
  font-weight: 400;
`
export const TextRegular12 = styled(Text)`
  font-size: 12px;
  font-weight: 400;
`
// Italic Variations
export const ItalicMedium24 = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  font-style: italic;
`

export const ItalicMedium18 = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
`

// Underlined Variations
export const UnderlinedRegular18 = styled(Text)`
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
`

export const UnderlinedMedium14 = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
`

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${({ color }) => color || textColors.primary}; // Default to primary color if none provided
`

export const GradientText = (TextStyle) => styled(TextStyle)`
  background: ${({ gradient }) => gradient}; /* Apply the gradient */
  -webkit-background-clip: text; /* Clip the background to the text (for Safari/Chrome) */
  -webkit-text-fill-color: transparent; /* Make the text transparent to show the gradient */
  background-clip: text; /* Clip the background to the text (for other browsers) */
  color: transparent; /* Set text color to transparent */
`

// Example usage
export const GradientTextRegular14 = GradientText(TextRegular14)
export const GradientH3 = GradientText(H3)
export const GradientH6 = GradientText(H6)
