// utils/smoothScroll.js

/**
 * Smoothly scrolls the page to the bottom over a specified duration.
 * @param {number} duration - The duration of the scroll in milliseconds.
 * @param {function} callback - A callback function to execute after scrolling completes.
 */
export const smoothScrollToBottom = (duration = 2000, callback) => {
    const startY = window.scrollY;
    const endY = document.documentElement.scrollHeight - window.innerHeight;
    const distance = endY - startY;
    let startTime = null;
  
    // Ease-out function for smooth scrolling effect
    const easing = (t) => t * (2 - t);
  
    const scroll = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      const easeProgress = easing(progress);
  
      // Calculate the next scroll position
      const nextY = startY + distance * easeProgress;
  
      // Scroll to the calculated position
      window.scrollTo(0, nextY);
  
      // Stop if we have reached or exceeded the target position
      if (timeElapsed < duration && Math.abs(nextY - endY) > 1) {
        requestAnimationFrame(scroll);
      } else {
        // Ensure the final scroll lands exactly at the bottom
        window.scrollTo(0, endY);
        if (callback) callback(); // Execute the callback when scroll is complete
      }
    };
  
    requestAnimationFrame(scroll);
  };