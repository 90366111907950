import { useEffect } from 'react';
import { scroller } from 'react-scroll';

export const handleSmoothScroll = (navigate, location, sectionId, smooth = true, offset = 0) => {
  if (location.pathname !== '/') {
    // Navigate to the home page and then scroll to the section
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: smooth ? 800 : 0, // Use 0 for instant scroll
        delay: 0,
        smooth: smooth ? 'easeInOutQuart' : 'auto',
        offset, // Optional offset to control scroll position
      });
    }, 100);
  } else {
    // If already on the home page, just scroll to the section
    scroller.scrollTo(sectionId, {
      duration: smooth ? 800 : 0, // Use 0 for instant scroll
      delay: 0,
      smooth: smooth ? 'easeInOutQuart' : 'auto',
      offset, // Optional offset to control scroll position
    });
  }
};
// Generic function to handle closing the menu after navigation
export const handleMenuCloseAfterNavigation = (navigate, location, sectionId, smooth, offset, onClose) => {
  handleSmoothScroll(navigate, location, sectionId, smooth, offset);
  setTimeout(() => {
    onClose();  // Call the close callback after navigation
  }, 300); // You can adjust the timing as needed
};

// Hook to manage outside clicks for mobile menu closing
export const useOutsideClickHandler = (menuRef, onClose) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, onClose]);
};
