import React from 'react';
import { TextStyles } from '../../styles/index';
import { ReactComponent as ChatIcon } from '../../assets/icons/chat_icon.svg';
import { navStylesConfig } from '../../config/navStylesConfig';
import { useNavigate, useLocation } from 'react-router-dom'; // Import React Router hooks
import { handleSmoothScroll } from '../../helpers/navigationHelpers';  // Import the helper function
import '../../styles/effects/underline.scss'
const ScrollView = ({ mode }) => {
  const {
    logoColor,
    textColor,
    backgroundColor,
    dotColor,
    svgColor,
    svgBG,
  } = navStylesConfig[mode];

  const navLogo = logoColor === 'dark' ? '/static/Nav/logo_dark.png' : '/static/Nav/logo_light.png';
  const NavText = TextStyles.TextMedium14;
  const linkColor = mode ==='dark' ? 'dark-link' : 'light-link'
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className="fixed top-8 left-0 w-full flex justify-center items-center transition-all duration-1000 z-20"
    >
      <nav className="flex items-center space-x-4 rounded-full px-4 py-3 " style={{ backgroundColor }}>
        {/* Logo Link */}
        <a href="/" >
          <img src={navLogo} alt="Nav Logo" className="w-[31px] h-auto" />
        </a>

        {/* Navigation Links */}
        <a onClick={() => handleSmoothScroll(navigate, location, 'about', true)} className={`flex items-center cursor-pointer hoverUnderline ${linkColor}`}>
          <NavText color={textColor}>About</NavText>
        </a>
        <TextStyles.Dot color={dotColor} />
        <a onClick={() => handleSmoothScroll(navigate, location, 'workflow', true)} className={`flex items-center cursor-pointer hoverUnderline ${linkColor}`}>
          <NavText color={textColor}>Workflow</NavText>
        </a>
        <TextStyles.Dot color={dotColor} />
        <a onClick={() => handleSmoothScroll(navigate, location, 'testimonials', true)} className={`flex items-center cursor-pointer hoverUnderline ${linkColor}`}>
          <NavText color={textColor}>Testimonials</NavText>
        </a>
        <TextStyles.Dot color={dotColor} />
        {/* Instant Scroll with 10px Offset for Contact Section */}
        <a onClick={() => handleSmoothScroll(navigate, location, 'contact', false, -1)} className={`flex items-center cursor-pointer hoverUnderline ${linkColor}`}>
          <NavText color={textColor}>Contact</NavText>
        </a>

        {/* Contact Button with Icon */}
        <a href="/contact">
          <div
            className="flex items-center justify-center w-[48px] h-[34px] rounded-full ml-2 z-10"
            style={{ backgroundColor: svgBG, color: svgColor }} 
          >
            <ChatIcon className="w-[20px] h-auto icon" />
          </div>
        </a>
      </nav>
    </div>
  );
};

export default ScrollView;
