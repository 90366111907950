import React, { useState, useEffect } from 'react';
import styles from './preloader.module.scss';

export const PreLoader2 = () => {
    const [showStaticLogo, setShowStaticLogo] = useState(false);
    const [expandStar, setExpandStar] = useState(false);
    const [fadeOutLogo, setFadeOutLogo] = useState(false);

    useEffect(() => {
        const fadeOutTimer = setTimeout(() => setFadeOutLogo(true), 2200);
        const logoTimer = setTimeout(() => {
            setShowStaticLogo(true);
            const starTimer = setTimeout(() => setExpandStar(true), 0);
            return () => clearTimeout(starTimer);
        }, 2400);

        return () => {
            clearTimeout(fadeOutTimer);
            clearTimeout(logoTimer);
        };
    }, []);

    return (
        <div className={`${styles.smoothChangeBg} w-full h-screen flex justify-center items-center relative ${expandStar ? 'bg-white' : 'bg-dark-blue'}`}>
            {showStaticLogo ? (
                <>
                    <img src="/static/PreLoader/logo_with_text.png" alt="Static Logo" className={styles.staticLogo} />
                    {expandStar && (
                        <div className={styles.expandingStar}>
                            <img src="/static/PreLoader/star_bg.png" alt="Expanding Star" className={styles.expandingStarImg} />
                        </div>
                    )}
                </>
            ) : (
                <div className={`flex items-center ${fadeOutLogo ? styles.logoFadeOut : ''}`}>
                    <div className={styles.hexagonContainer}>
                        <img src="/static/PreLoader/hexagon.png" className={`${styles.hexagonLayer} ${styles.hexagonImg}`} alt="Hexagon Layer 1" />
                        <img src="/static/PreLoader/heart.png" className={`${styles.hexagonLayer} ${styles.heartImg}`} alt="Hexagon Layer 2" />
                        <img src="/static/PreLoader/Star.png" className={`${styles.hexagonLayer} ${styles.starImg}`} alt="Hexagon Layer 3" />
                    </div>
                    <img src="/static/PreLoader/text_white.png" className={`${styles.edenText} ${styles.logoTextLeft}`} alt="Text" />
                </div>
            )}
        </div>
    );
};