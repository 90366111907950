// navStylesConfig.js
import { colors } from '../styles/index'; // Adjust the path to your styles

export const navStylesConfig = {
  light: {
    logoColor: 'light',
    textColor: colors.white,
    backgroundColor: colors.grey30,
    dotColor: colors.grey30,
    svgColor: colors.white,
    mobileLogoColor: 'light', // For mobile nav specifically
    mobileTextColor: colors.white, // Text color for mobile light mode
    menuBackgroundColor: colors.darkBlue, // Light mode background for menu
    menuTextColor: colors.white, // Light mode text color for menu
    svgBackgroundMobile: colors.grey70,
    mobileButtonHover: colors.purple15,
    mobileButtonText: colors.darkBlue,
    chatSVGColor: colors.purple15,
    svgBG: colors.gray30,

  },
  dark: {
    logoColor: 'dark',
    textColor: colors.darkBlue,
    backgroundColor: colors.purple10,
    dotColor: colors.purple40,
    svgColor: colors.darkBlue,
    mobileLogoColor: 'dark', // For mobile nav specifically
    mobileTextColor: colors.darkBlue, // Text color for mobile dark mode
    menuBackgroundColor: colors.white, // Light mode background for menu
    menuTextColor: colors.darkBlue, // Light mode text color for menu
    svgBackgroundMobile: colors.grey60,
    mobileButtonHover: colors.lightGreen,
    mobileButtonText: colors.white,
    chatSVGColor: colors.greyTwo,
    svgBG: colors.gray30,
  },
};