// Background.js
import React, { useEffect } from 'react'

const Background = ({ backgroundVideoSrc, backgroundStyles = {}, getBackgroundStyle, activeSection }) => {
  const computedBackgroundStyles = getBackgroundStyle ? getBackgroundStyle() : backgroundStyles

  useEffect(() => {
    // 当 activeSection 改变时，可以在这里执行一些副作用
  }, [activeSection])

  return (
    <div className='absolute top-0 left-0 w-full h-full z-0 bg-black'>
      {backgroundVideoSrc && (
        <video
          key={backgroundVideoSrc}
          autoPlay
          loop
          muted
          playsInline
          className='absolute top-0 left-0 w-full h-full object-cover'>
          <source src={backgroundVideoSrc} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}
      <div className='absolute top-0 left-0 w-full h-full' style={computedBackgroundStyles} />
    </div>
  )
}

export default Background
