// src/pages/ContactPage.js
import React from 'react';
import ContactForm from '../modules/ContactForm';
import Nav from '../components/Nav/Nav';
import Footer from '../components/Footer';
import useSectionObserver from '../hooks/useSectionObserver'; 

const sections = ['footer', 'contactForm'];

const ContactPage = () => {
    const mode = useSectionObserver(sections); 

  return (
    <div>
      <Nav mode={mode} /> 
      <section id="contactForm">
        <ContactForm />
      </section>
      <section id="footer">
      <Footer mode = 'light'/>
      </section>
    </div>
  );
};

export default ContactPage;
