import React from 'react'
import { motion } from 'framer-motion'

const pageMessage = [
  {
    title: 'Appointment',
    description: 'AI Scheduling',
    todays: '0',
    cost: '10',
  },
  {
    title: 'Registration',
    description: "AI Intake (Eden’s 'Tap to Check-In')",
    todays: '0',
    cost: '0',
  },
  {
    title: 'Insurance',
    description: 'Real-time Insurance Verification',
    todays: '1',
    cost: '3',
  },
  {
    title: 'Medical Records',
    description: 'Instant-Access Medical Records',
    todays: '1',
    cost: '3',
  },
  {
    title: 'Patient Communication',
    description: 'AI Referrals',
    todays: '0',
    cost: '5',
  },
  {
    title: 'Patient Correspondence',
    description: 'AI Fax',
    todays: '0',
    cost: '5',
  },
  {
    title: 'Billing',
    description: 'AI Billing',
    todays: '10',
    cost: '18',
  },
]

const specialMessage = [
  {
    left: '<',
    right: 'min',
  },
]

const Solution = ({ currentIndex, isVisible }) => {
  // define framer-motion variants for fading in from top and fading out to top
  const fadeInFromTop = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1.5 } },
    exit: { opacity: 0, y: -20, transition: { duration: 1.5 } }, // exit variant for fade out upwards
  }

  const staggerChildren = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    exit: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  }

  return (
    <motion.div
      className='flex flex-col lg:flex-row w-[335px] lg:w-[630px] h-[118px] lg:h-[106px] z-10'
      variants={staggerChildren}
      initial='hidden'
      animate={isVisible ? 'visible' : 'exit'}
      exit='exit'>
      <motion.div className='flex-grow' variants={fadeInFromTop}>
        <div className='text-xs lg:text-base font-normal leading-[14.09px] lg:leading-[18.78px] text-white w-[28px] lg:w-[37px] h-[14px] lg:h-[19px]'>
          Solution:
        </div>
        <div className='text-xl lg:text-[32px] font-medium leading-[23.48px] lg:leading-[37.57px] text-white w-[335px] lg:w-[308px] h-[23px] lg:h-[76px]'>
          {pageMessage[currentIndex].description}
        </div>
      </motion.div>

      <div className='h-[57px] lg:h-[100px] flex flex-row'>
        <motion.div
          className='flex flex-col justify-center items-center w-[109px] rounded-[24px]'
          style={{
            background: 'rgba(184, 228, 189, 0.2)',
            backdropFilter: 'blur(294.5px)',
          }}
          variants={fadeInFromTop}>
          <div className='text-xs lg:text-base font-normal leading-[14.09px] lg:leading-[18.78px] text-[rgba(184,228,189,1)]'>
            Todays:
          </div>
          <div className='text-2xl lg:text-[42px] font-normal leading-[28.12px] lg:leading-[49.31px] text-[rgba(184,228,189,1)]'>
            <div className='flex flex-row'>
              {currentIndex === 2 || currentIndex === 3 ? (
                <>
                  <span className='text-[10px] leading-[11.74px] lg:leading-[14.09px] lg:text-xs mt-2 lg:mt-3 me-2'>
                    {specialMessage[0].left}
                  </span>
                  {pageMessage[currentIndex].todays}
                  <span className='text-[10px] leading-[11.74px] lg:leading-[14.09px] lg:text-xs mt-1 lg:mt-2 ms-1 lg:ms-2'>
                    {specialMessage[0].right}
                  </span>
                </>
              ) : (
                pageMessage[currentIndex].todays
              )}
            </div>
          </div>
        </motion.div>

        <motion.div
          className='flex flex-col justify-center items-center w-[184px] rounded-[24px] ms-[9px]'
          style={{
            background: 'rgba(184, 228, 189, 0.2)',
            backdropFilter: 'blur(294.5px)',
          }}
          variants={fadeInFromTop}>
          <div className='text-xs lg:text-base font-normal leading-[14.09px] lg:leading-[18.78px] text-[rgba(184,228,189,1)]'>
            Cost:
          </div>
          <div className='text-2xl lg:text-[42px] font-normal leading-[28.12px] lg:leading-[49.31px] text-[rgba(184,228,189,1)]'>
            {pageMessage[currentIndex].cost}$
          </div>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default Solution
