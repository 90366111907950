// Import the styled-components library and SVG components
import styled, { css } from 'styled-components';
import { ReactComponent as ChatIcon } from '../../assets/icons/chat_icon.svg'; // Ensure correct import path
import { ReactComponent as DotsIcon } from '../../assets/icons/Dots.svg'; // Example path for Dots icon
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg'; // Example path for Close icon
import { colors } from './colors'; // Import your colors

// Base styles for SVGs to apply consistent fill
const baseSVGStyles = css`
  & path {
    fill: ${({ svgColor }) => svgColor || colors.white}; // Default fill color for SVG
  }
`;

// Wrapper component to provide the rounded background, padding, and additional styles
const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || colors.grey30}; // Apply background color
  border-radius: 50%; // Fully rounded
  padding: ${({ bgSize }) => bgSize || '8px'}; // Control background padding size
`;

// Styled SVG components with shared base styles
export const ChatIcon1 = ({ svgColor, svgSize, bgColor, bgSize, marginLeft, ...props }) => (
  <IconWrapper bgColor={bgColor} bgSize={bgSize} marginLeft={marginLeft} {...props}>
    <StyledChatIcon svgColor={svgColor} svgSize={svgSize} />
  </IconWrapper>
);

export const StyledChatIcon = styled(ChatIcon)`
  ${baseSVGStyles} // Apply shared SVG styles
  width: ${({ svgSize }) => svgSize || '18px'}; // Control SVG size
  height: auto;
`;

export const DotsIcon1 = ({ svgColor, svgSize, bgColor, bgSize, marginLeft, ...props }) => (
  <IconWrapper bgColor={bgColor} bgSize={bgSize} marginLeft={marginLeft} {...props}>
    <StyledDotsIcon svgColor={svgColor} svgSize={svgSize} />
  </IconWrapper>
);

export const StyledDotsIcon = styled(DotsIcon)`
  ${baseSVGStyles} // Apply shared SVG styles
  width: ${({ svgSize }) => svgSize || '18px'}; // Control SVG size
  height: auto;
`;

export const CloseIcon1 = ({ svgColor, svgSize, bgColor, bgSize, marginLeft, ...props }) => (
  <IconWrapper bgColor={bgColor} bgSize={bgSize} marginLeft={marginLeft} {...props}>
    <StyledCloseIcon svgColor={svgColor} svgSize={svgSize} />
  </IconWrapper>
);

export const StyledCloseIcon = styled(CloseIcon)`
  ${baseSVGStyles} // Apply shared SVG styles
  width: ${({ svgSize }) => svgSize || '18px'}; // Control SVG size
  height: auto;
`;
