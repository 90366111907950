// src/styles/buttons/RadioButtonInsideButton.js
import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../core/colors'
import withButtonHover from '../../hoc/withButtonHover' // Import the hover HOC
import ButtonOne from './ButtonOne' // Import the base button component

// Extend the ButtonOne component to add radio button logic
const ButtonWithRadio = styled(ButtonOne)`
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the radio button and the text */
  position: relative; /* Needed for positioning hover effect */
  padding: 10px 30px; /* Add padding if needed */
  transition:
    background-color 0.3s,
    border-color 0.3s;
  z-index: 1; /* Ensure content is above hover effect */
  color: ${colors.darkBlue};
  border: 2px solid ${colors.white241};
  cursor: pointer; /* Ensure cursor is a pointer to indicate clickability */

  &:hover input[type='radio'] {
    border: 4px solid ${colors.darkBlue}; /* Change border color on hover */
  }
`

// Style the radio button to fit inside the button
const StyledRadioButton = styled.input.attrs({ type: 'radio' })`
  appearance: none; /* Remove default radio button appearance */
  min-width: 12px;
  min-height: 12px;
  border: 2px solid ${colors.lightGreen};
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition:
    border-color 0.3s,
    background-color 0.3s;
  z-index: 4; /* Ensure radio button is above hover effect */

  &:checked {
    background-color: ${colors.lightGreen}; /* Color when checked */
    border: 4px solid ${colors.darkBlue}; /* Change border color on hover */
  }
`

// Combine HoverButtonOne with the radio button
const HoverableButtonWithRadio = withButtonHover(ButtonWithRadio) // Applying hover effect with the HOC

// The combined component with radio button inside the button
const RadioButtonInsideButton = ({ name, value, checked, onChange, textStyle: TextStyle }) => {
  const handleButtonClick = () => {
    // Trigger onChange manually with the correct value when the button is clicked
    if (onChange) {
      const event = {
        target: { name, value },
      }
      onChange(event)
    }
  }

  return (
    <HoverableButtonWithRadio
      hoverColor={colors.lightGreen}
      bgColor={colors.white}
      onClick={handleButtonClick} // Handle click on the entire button
      style={{
        backgroundColor: checked ? colors.lightGreen : '', // Update button color when checked
      }}>
      <StyledRadioButton name={name} value={value} checked={checked} onChange={onChange} />
      {/* Render the text using the provided text style component */}
      {TextStyle && <TextStyle>{value}</TextStyle>}
    </HoverableButtonWithRadio>
  )
}

export default RadioButtonInsideButton
