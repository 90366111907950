import React, { useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import Task from '../../components/WorkFlowComponents/Task'
import Solution from '../../components/WorkFlowComponents/Solution'
import styles from './Workflow.module.scss' // Assuming you are using CSS modules

const WorkflowSection = ({ isVisible, currentIndex, videoSrc, containerStyles = '', videoStyles = '' }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    if (isVisible && videoRef.current) {
      videoRef.current.play()
    } else if (videoRef.current) {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
    }
  }, [isVisible])

  const videoVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  }

  // Add the gradient background class to the first two sections
  const gradientBackgroundClass = currentIndex === 0 || currentIndex === 1 ? styles['gradient-background'] : ''

  return (
    <div className={`relative h-screen flex flex-row items-center ${containerStyles} ${gradientBackgroundClass}`}>
      <div className='relative z-10 flex w-full justify-center lg:justify-between mx-[5vw] lg:mt-[10vh]'>
        <div className='flex flex-col justify-center'>
          <div className='hidden lg:block'>
            <Task currentIndex={currentIndex} isVisible={isVisible} />
          </div>
        </div>
        <div className='hidden lg:block xl:w-[15vw] 2xl:w-[30vw]' />
        <div className='flex flex-col items-center justify-center'>
          <div>
            <div className='block lg:hidden'>
              <Task currentIndex={currentIndex} isVisible={isVisible} />
            </div>
            <Solution currentIndex={currentIndex} isVisible={isVisible} />
          </div>
          <motion.video
            ref={videoRef}
            loop={currentIndex === 5 || currentIndex === 6 ? false : true}
            muted
            playsInline
            variants={videoVariants}
            initial='hidden'
            animate={isVisible ? 'visible' : 'hidden'}
            className={videoStyles}>
            <source src={videoSrc.webm} type='video/webm' />
            <source src={videoSrc.mov} type='video/quicktime' />
            Your browser does not support the video tag.
          </motion.video>
        </div>
      </div>
    </div>
  )
}

export default WorkflowSection
