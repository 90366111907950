import React from 'react'

const Voice = ({ size = 44 }) => (
  <svg width={size} height={size} viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='22' cy='22' r='22' fill='#B8E4BD' />
    <path
      d='M24.4415 13.0609C24.4211 13.0405 24.3999 13.0205 24.3792 13L23.2223 14.1578C24.2126 15.1303 25.0007 16.2894 25.541 17.5683C26.0813 18.8472 26.3632 20.2206 26.3703 21.6091C26.3774 22.9976 26.1096 24.3737 25.5824 25.6581C25.0552 26.9425 24.2791 28.1096 23.2988 29.0922L24.4556 30.25C26.7289 27.9674 28.0041 24.8754 28.0014 21.6526C27.9987 18.4299 26.7185 15.3397 24.4415 13.0609Z'
      fill='#010122'
    />
    <path
      d='M19.8906 17.4922C20.4427 18.0271 20.8832 18.6665 21.1862 19.3732C21.4893 20.0799 21.6491 20.8399 21.6562 21.6089C21.6633 22.3779 21.5177 23.1407 21.2277 23.8529C20.9377 24.5651 20.5092 25.2126 19.9671 25.7576L21.1202 26.9115C21.814 26.2151 22.3627 25.3879 22.7348 24.4777C23.1068 23.5675 23.2947 22.5926 23.2876 21.6092C23.2805 20.6259 23.0785 19.6537 22.6934 18.749C22.3082 17.8443 21.7475 17.0251 21.0438 16.3389L19.8906 17.4922Z'
      fill='#010122'
    />
    <path
      d='M18.25 22C18.25 22.8284 17.5784 23.5 16.75 23.5C15.9216 23.5 15.25 22.8284 15.25 22C15.25 21.1716 15.9216 20.5 16.75 20.5C17.5784 20.5 18.25 21.1716 18.25 22Z'
      fill='#010122'
    />
  </svg>
)

export default Voice
