const sections = [
  {
    id: 1,
    currentIndex: 0,
    videoSrc: {
      webm: '/static/WorkFlow/1-Phone (Appointment Scheudling).webm',
      mov: '/static/WorkFlow/1-mobile-video.mov'
    },
    backgroundVideoSrc: '/static/WorkFlow/1-Background (Appointment Scheudling).mp4',
    containerStyles: 'w-full',
    backgroundStyles: {
      background: `linear-gradient(360deg, rgba(2, 2, 35, 0) 0%, rgba(2, 2, 35, 0.8) 100%)`,
    },
    videoStyles: 'h-[50vh] mt-10 lg:h-[70vh] w-[70vw] lg:mt-[40px]',
    textcolor: 'text-white',
  },
  {
    id: 2,
    currentIndex: 1,
    videoSrc: {
      webm: '/static/WorkFlow/2-Patient Registration-Main Animatioon.webm',
      mov: '/static/WorkFlow/2-Patient Registration-Main Animatioon (1).mov'
    },
    backgroundVideoSrc: '/static/WorkFlow/2-Patient Registration & Intake-Background.mp4',
    containerStyles: 'w-full',
    backgroundStyles: {
      background: `linear-gradient(360deg, rgba(2, 2, 35, 0) 0%, rgba(2, 2, 35, 0.8) 100%)`,
    },
    videoStyles: 'h-[50vh] mt-10 lg:h-[70vh] w-[90vw] lg:mt-[70px]',
    textcolor: 'text-black',
  },
  {
    id: 3,
    currentIndex: 2,
    videoSrc: {
      webm: '/static/WorkFlow/3-Insurance Verification (transparent).webm',
      mov: '/static/WorkFlow/3-Insurance Verification (transparent).mov'
    },
    videoStyles: 'h-[45vh] mt-20 lg:h-[70vh] w-[90vw] lg:mt-[40px]',
    textcolor: 'text-white',
    getBackgroundStyle: () => ({
      background:
        window.innerWidth >= 1024
          ? `radial-gradient(88.05% 100% at 49.22% 0%, rgba(1, 1, 34, 0) 61.53%, #010122 100%)`
          : `linear-gradient(360deg, rgba(2, 2, 35, 0) 0%, rgba(2, 2, 35, 0.8) 100%)`,
    }),
  },
  {
    id: 4,
    currentIndex: 3,
    videoSrc: {
      webm: '/static/WorkFlow/4-Instant-Access-2x.webm',
      mov: '/static/WorkFlow/4-Instant-Access-2x.mov'
    },
    videoStyles: 'h-[45vh] mt-20 lg:h-[70vh] w-[90vw] lg:mt-[40px]',
    textcolor: 'text-white',
    getBackgroundStyle: () => ({
      background:
        window.innerWidth >= 1024
          ? `radial-gradient(88.05% 100% at 49.22% 0%, rgba(1, 1, 34, 0) 61.53%, #010122 100%)`
          : `linear-gradient(360deg, rgba(2, 2, 35, 0) 0%, rgba(2, 2, 35, 0.8) 100%)`,
    }),
  },
  {
    id: 5,
    currentIndex: 4,
    videoSrc: {
      webm: '/static/WorkFlow/5-AI Referrals-2x.webm',
      mov: '/static/WorkFlow/5-AI Referrals-2x.mov'
    },
    videoStyles: 'h-[45vh] mt-20 lg:h-[70vh] w-[90vw] lg:mt-[40px]',
    textcolor: 'text-black',
    getBackgroundStyle: () => ({
      background:
        window.innerWidth >= 1024
          ? `radial-gradient(88.05% 100% at 49.22% 0%, rgba(1, 1, 34, 0) 61.53%, #010122 100%)`
          : `linear-gradient(360deg, rgba(2, 2, 35, 0) 0%, rgba(2, 2, 35, 0.8) 100%)`,
    }),
  },
  {
    id: 6,
    currentIndex: 5,
    videoSrc: {
      webm: '/static/WorkFlow/6-AI-Fax-2x.webm',
      mov: '/static/WorkFlow/6-AI-Fax-2x.mov'
    },
    videoStyles: 'h-[45vh] mt-20 lg:h-[70vh] w-[90vw] lg:mt-[40px]',
    textcolor: 'text-white',
    getBackgroundStyle: () => ({
      background:
        window.innerWidth >= 1024
          ? `radial-gradient(88.05% 100% at 49.22% 0%, rgba(1, 1, 34, 0) 61.53%, #010122 100%)`
          : `linear-gradient(360deg, rgba(2, 2, 35, 0) 0%, rgba(2, 2, 35, 0.8) 100%)`,
    }),
  },
  {
    id: 7,
    currentIndex: 6,
    videoSrc: {
      webm: '/static/WorkFlow/7-Billing-Chart-2x-size.webm',
      mov: '/static/WorkFlow/7-Billing-Chart-2x-size.mov'
    },
    videoStyles: 'h-[45vh] mt-20 lg:h-[70vh] w-[90vw] lg:mt-[40px]',
    textcolor: 'text-white',
    getBackgroundStyle: () => ({
      background:
        window.innerWidth >= 1024
          ? `radial-gradient(88.05% 100% at 49.22% 0%, rgba(1, 1, 34, 0) 61.53%, #010122 100%)`
          : `linear-gradient(360deg, rgba(2, 2, 35, 0) 0%, rgba(2, 2, 35, 0.8) 100%)`,
    }),
  },
];

export default sections;
