// styles/core/theme.js - style constants
import { colors } from './colors'

export const textColors = {
  primary: colors.darkBlue,
  secondary: colors.greyOne,
  accent: colors.greyTwo,
}

export const backgroundColors = {
  primary: colors.white,
  secondary: colors.darkBlue,
  accent: colors.greyThree,
}
