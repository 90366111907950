// src/hoc/withButtonHover.js
import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../styles/core/colors'

const withButtonHover = (WrappedComponent) => {
  const HoverableButton = styled(WrappedComponent)`
    position: relative; /* Add this to make the hover circle position relative to the button */
    overflow: hidden; /* Ensure the circle stays inside the button */
    z-index: 1; /* Ensure button content stays above the hover effect */

    &::before {
      content: '';
      position: absolute;
      top: ${({ circlePosition }) => circlePosition.top || '50%'};
      left: ${({ circlePosition }) => circlePosition.left || '50%'};
      width: 0;
      height: 0;
      background-color: ${({ hoverColor }) => hoverColor || colors.grey30}; /* Default hover color */
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transition:
        width 0.6s ease,
        height 0.6s ease; /* Smooth expansion */
      z-index: -1; /* Set z-index to ensure the circle stays behind all content */
    }

    &:hover::before {
      width: 1500px;
      height: 1500px;
    }
  `

  return (props) => {
    const [circlePosition, setCirclePosition] = useState({ top: '50%', left: '50%' })

    const handleMouseMove = (e) => {
      const rect = e.target.getBoundingClientRect()
      const top = `${((e.clientY - rect.top) / rect.height) * 100}%`
      const left = `${((e.clientX - rect.left) / rect.width) * 100}%`
      setCirclePosition({ top, left })
    }

    return (
      <HoverableButton
        {...props}
        circlePosition={circlePosition} // Pass hover position to the styled component
        onMouseMove={handleMouseMove}
      />
    )
  }
}

export default withButtonHover
