import React, { useEffect, useState, useCallback, useRef } from 'react'
import { motion } from 'framer-motion'
import WorkflowSection from './WorkflowSection'
import Background from '../../components/WorkFlowComponents/Background'
import LineAnimation from '../../components/WorkFlowComponents/LineAnimation'
import sections from './sectionsConfig'
import PasswordEntry from './PasswordEntry'
import './index.module.scss'

const WorkFlow = ({ setDisableNav }) => {
  const [activeSection, setActiveSection] = useState(0)
  const [isWorkflowActive, setIsWorkflowActive] = useState(false)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [touchStart, setTouchStart] = useState(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [enteredDigits, setEnteredDigits] = useState(0)
  const workflowRef = useRef(null)
  const totalSections = sections.length

  const centerWorkflow = () => {
    if (workflowRef.current) {
      const elementRect = workflowRef.current.getBoundingClientRect()
      const scrollTo = window.scrollY + elementRect.top - (window.innerHeight - elementRect.height) / 2
      window.scrollTo({ top: scrollTo, behavior: 'auto' })
    }
  }

  const centerAndStartWorkflow = () => {
    centerWorkflow()
    startWorkflow()
  }

  const startWorkflow = () => {
    const currentScroll = window.scrollY
    setScrollPosition(currentScroll)
    setIsWorkflowActive(true)
    setActiveSection(1)
    setDisableNav(true)
    document.body.style.position = 'fixed'
    document.body.style.top = `-${currentScroll}px`
    document.body.style.left = '0'
    document.body.style.right = '0'
  }

  const endWorkflow = () => {
    setIsWorkflowActive(false)
    setActiveSection(0)
    setDisableNav(false)
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.left = ''
    document.body.style.right = ''
    window.scrollTo(0, scrollPosition)
  }

  const handleSectionChange = useCallback(
    (direction) => {
      if (isTransitioning || !isWorkflowActive) return
      setIsTransitioning(true)
      if (direction > 0 && activeSection < totalSections) {
        setActiveSection((prev) => prev + 1)
      } else if (direction < 0 && activeSection > 1) {
        setActiveSection((prev) => prev - 1)
      }
      if (activeSection === totalSections && direction > 0) {
        endWorkflow()
      }
      setTimeout(() => setIsTransitioning(false), 700)
    },
    [activeSection, isTransitioning, isWorkflowActive, totalSections],
  )

  const handleWheel = useCallback(
    (e) => {
      if (isWorkflowActive) {
        e.preventDefault()
        if (Math.abs(e.deltaY) >= 50) {
          handleSectionChange(e.deltaY)
        }
      }
    },
    [isWorkflowActive, handleSectionChange],
  )

  const handleTouchStart = useCallback(
    (e) => {
      if (isWorkflowActive) setTouchStart(e.touches[0].clientY)
    },
    [isWorkflowActive],
  )

  const handleTouchMove = useCallback(
    (e) => {
      if (!touchStart || !isWorkflowActive) return
      const deltaY = touchStart - e.touches[0].clientY
      if (Math.abs(deltaY) > 50) {
        e.preventDefault()
        handleSectionChange(deltaY)
        setTouchStart(null)
      }
    },
    [touchStart, isWorkflowActive, handleSectionChange],
  )

  useEffect(() => {
    const element = workflowRef.current
    if (!element) return
    element.addEventListener('wheel', handleWheel, { passive: false })
    element.addEventListener('touchstart', handleTouchStart, { passive: true })
    element.addEventListener('touchmove', handleTouchMove, { passive: false })

    return () => {
      element.removeEventListener('wheel', handleWheel)
      element.removeEventListener('touchstart', handleTouchStart)
      element.removeEventListener('touchmove', handleTouchMove)
    }
  }, [handleWheel, handleTouchStart, handleTouchMove, isWorkflowActive])

  const fadeVariants = {
    initial: { opacity: 0 },
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  const handleDigitEntered = () => {
    setEnteredDigits((prev) => Math.min(prev + 1, 5))
  }

  return (
    <div ref={workflowRef} className='relative h-screen overflow-hidden'>
      {/* Background Image with Dark Blue Overlay and Blob Reveal Effect */}
      <div className='background-container'>
        <div
          className='background-image'
          style={{
            backgroundImage: "url('/static/WorkFlow/password_backgroud.jpg')",
          }}>
          {[...Array(enteredDigits)].map((_, index) => (
            <div key={index} className={`blob blob-${index + 1}`} />
          ))}
        </div>
        <div className='dark-overlay' />
      </div>

      {!isWorkflowActive ? (
        <div className='relative h-full'>
          <div className='absolute inset-0 flex flex-col items-center justify-center'>
            <Background
              backgroundVideoSrc={sections[0].backgroundVideoSrc}
              backgroundStyles={sections[0].backgroundStyles}
              getBackgroundStyle={sections[0].getBackgroundStyle}
            />
            <motion.div
              className={`h-full w-full flex justify-center items-center ${sections[0].textcolor}`}
              initial='initial'
              animate='visible'
              exit='hidden'
              variants={fadeVariants}>
              <WorkflowSection
                isVisible={true}
                currentIndex={sections[0].currentIndex}
                videoSrc={sections[0].videoSrc}
                containerStyles={sections[0].containerStyles}
                videoStyles={sections[0].videoStyles}
                sectionIndex={sections[0].sectionIndex}
              />
            </motion.div>
          </div>

          <div className='absolute inset-0 backdrop-blur-md'></div>

          <div className='absolute inset-0 flex items-center justify-center'>
            <PasswordEntry
              onCorrectPassword={centerAndStartWorkflow}
              centerWorkflow={centerWorkflow}
              onDigitEntered={handleDigitEntered}
            />
          </div>
        </div>
      ) : (
        <div className='relative h-screen w-full'>
          <Background
            backgroundVideoSrc={sections[activeSection - 1]?.backgroundVideoSrc}
            backgroundStyles={sections[activeSection - 1]?.backgroundStyles}
            getBackgroundStyle={sections[activeSection - 1]?.getBackgroundStyle}
          />

          {sections.map((section) => (
            <motion.div
              key={section.id}
              className={`h-screen w-full absolute top-0 left-0 flex justify-center items-center ${section.textcolor}`}
              initial='initial'
              animate={activeSection === section.id ? 'visible' : 'hidden'}
              variants={fadeVariants}
              transition={{ duration: 0.5 }}
              style={{
                zIndex: activeSection === section.id ? 2 : 0,
                height: '100dvh',
                willChange: 'opacity',
              }}>
              <WorkflowSection
                isVisible={activeSection === section.id}
                currentIndex={section.currentIndex}
                videoSrc={section.videoSrc}
                containerStyles={section.containerStyles}
                videoStyles={section.videoStyles}
                sectionIndex={section.sectionIndex}
              />
            </motion.div>
          ))}

          {isWorkflowActive && (
            <div className='absolute top-0 lg:top-[20vh] left-0 lg:left-[17vw] min-w-[300px] w-full lg:w-[600px] px-4 lg:px-0 z-[1]'>
              <LineAnimation isVisible={isWorkflowActive} sectionIndex={activeSection} isContentLoaded={true} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default WorkFlow
