import { useEffect, useState, useRef } from 'react';
import { smoothScrollToBottom } from '../utils/smoothScroll';

// Define the section modes based on IDs
const sectionModes = {
  home: 'light',
  hero: 'dark',
  about: 'dark',
  workflow: 'light',
  testimonials: 'dark',
  contact: 'light',
  footer: 'light',
  contactForm: 'dark',
};

const useSectionObserver = (sections, onFooterVisible) => {
  const [currentMode, setCurrentMode] = useState('light');
  const hasScrolledToBottom = useRef(false);
  const activeSection = useRef(null);

  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    const sectionElements = sections.map((id) => document.getElementById(id));

    if (!navbar) {
      console.error('Navbar not found. Ensure it has the className="navbar".');
      return;
    }

    if (sectionElements.includes(null)) {
      console.error('One or more sections not found. Ensure all sections have the correct IDs.');
      sectionElements.forEach((section, index) => {
        if (!section) {
          console.error(`Section with ID "${sections[index]}" not found.`);
        }
      });
      return;
    }

    const navbarHeight = navbar.offsetHeight || 50;

    // Scroll event listener to detect the section closest to the top of the viewport
    const handleScroll = () => {
      const scrollPosition = window.scrollY + navbarHeight;
      let closestSection = null;

      sectionElements.forEach((section) => {
        if (section) {
          const sectionTop = section.offsetTop;
          const sectionBottom = sectionTop + section.offsetHeight;

          // Check if the current scroll position is within the section
          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            closestSection = section.id;
          }
        }
      });

      if (closestSection && closestSection !== activeSection.current) {
        activeSection.current = closestSection;
        const mode = sectionModes[closestSection] || 'light';
        setCurrentMode(mode);
        console.log(`Active section: ${closestSection}, Mode set to: ${mode}`);
      }
    };

    // Scroll event listener to handle footer visibility for animations
    const handleFooterVisibility = () => {
      const footer = document.getElementById('footer');
      if (footer) {
        const footerRect = footer.getBoundingClientRect();
        if (footerRect.top < window.innerHeight && !hasScrolledToBottom.current) {
          hasScrolledToBottom.current = true;
          smoothScrollToBottom(1000, onFooterVisible);
        }
      }
    };

    // Combine both scroll detection and footer visibility into a single scroll handler
    const combinedScrollHandler = () => {
      handleScroll();
      handleFooterVisibility();
    };

    // Attach the combined scroll handler
    window.addEventListener('scroll', combinedScrollHandler);

    // Initial call to handleScroll to set the correct mode on page load
    handleScroll();

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('scroll', combinedScrollHandler);
    };
  }, [sections, onFooterVisible]);

  return currentMode;
};

export default useSectionObserver;
