import React from 'react'
import LeftArrow from '../../icons/LeftArrow'
import RightArrow from '../../icons/RightArrow'
import Circle from '../../icons/Circle'
import Voice from '../../icons/Voice'
import styles from './ConversationChange.module.scss'

const conversationTxt = [
  {
    messageOne: "The administrative burden has reached a point where it's affecting patient outcomes.",
    messageTwo: 'We need a system that prioritizes efficient care delivery over bureaucratic processes.',
    messageThree: 'Primary Care',
    messageFour: 'Catholic Health',
  },
  {
    messageOne: 'The amount of time and resources we spend on paperwork and administrative tasks is staggering.',
    messageTwo: 'It takes away from our ability to focus on patient care and contributes to burnout.',
    messageThree: 'Family Doctor',
    messageFour: 'Pembroke Primary Care',
  },
  {
    messageOne:
      'Navigating through insurance requirements and filling out endless forms detracts from the essence of medicine.',
    messageTwo: 'We become data entry clerks instead of healers.',
    messageThree: 'Internal Medicine',
    messageFour: 'Lehigh Valley',
    messageFive: 'Health Network',
  },
]

const ConversationChange = ({ goToPrev, goToNext, currentIndex }) => {
  return (
    <div
      className={`absolute w-[335px] lg:w-[435px] h-[314px] lg:h-[426px] top-[5vh] mt-[210px] lg:mt-5 lg:top-[50%] right-[3vw] lg:left-0 ${styles.alignTestimonial}`}
      style={{ transform: 'translateY(-50%)' }}>
      <div className='text-left font-medium italic leading-[28px] text-lg lg:text-xl xl:text-2xl 2xl:text-[28px]'>
        <div className='mb-2 lg:mb-9'>{conversationTxt[currentIndex].messageOne}</div>
        <div>{conversationTxt[currentIndex].messageTwo}</div>
      </div>
      <div className='flex flex-row items-center justify-between mt-9 lg:mt-8 xl:mt-12 2xl:mt-16'>
        <button className='h-8 lg:h-11 max-w-[176px] lg:max-w-[210px] bg-white rounded-[75px]'>
          <div className='text-base font-medium text-[rgb(139,139,139)] px-2 lg:px-6'>
            {conversationTxt[currentIndex].messageThree}
          </div>
        </button>
        <div className='text-end font-medium text-base lg:text-xl max-w-[222px]'>
          {conversationTxt[currentIndex].messageFour}
          <br />
          {currentIndex === 2 ? conversationTxt[currentIndex].messageFive : null}
        </div>
      </div>
      <div className='flex items-center justify-between mt-9 lg:mt-10 xl:mt-14 max-h-[10vh]'>
        <button onClick={goToPrev}>
          <LeftArrow className='hidden lg:block' width={27} height={14} />
          <LeftArrow className='block lg:hidden' width={15.43} height={8} />
        </button>
        <div className='relative flex flex-row items-center justify-between w-[116px] h-12'>
          <div className='relative'>
            <Circle width={56} height={56} />
            <div className={styles.indexText}>{currentIndex + 1}</div>
          </div>
          <Voice size={44} />
        </div>
        <button onClick={goToNext}>
          <RightArrow className='hidden lg:block' width={27} height={14} />
          <RightArrow className='block lg:hidden' width={15.43} height={8} />
        </button>
      </div>
    </div>
  )
}

export default ConversationChange
