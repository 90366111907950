import React from 'react'

const Circle = ({ width = 56, height = 56 }) => (
  <svg width={width} height={height} viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M56 28C56 21.4373 53.6948 15.0831 49.4869 10.0469C45.279 5.01074 39.4358 1.61265 32.9776 0.445986C26.5194 -0.720675 19.8566 0.418228 14.1526 3.66383C8.44861 6.90942 4.06589 12.0555 1.76974 18.2034C-0.526419 24.3513 -0.590109 31.1105 1.58979 37.3006C3.76969 43.4907 8.05466 48.7184 13.6965 52.0709C19.3383 55.4234 25.9784 56.6877 32.4575 55.6429C38.9365 54.5982 44.8427 51.3108 49.1448 46.3548L46.9638 44.4616C43.1055 48.9064 37.8085 51.8547 31.9977 52.7917C26.187 53.7287 20.2317 52.5949 15.1718 49.5881C10.1119 46.5814 6.2689 41.8929 4.31384 36.3413C2.35879 30.7896 2.41591 24.7277 4.47523 19.2139C6.53455 13.7001 10.4652 9.08479 15.5809 6.17395C20.6965 3.26312 26.6721 2.24169 32.4642 3.28802C38.2562 4.33434 43.4968 7.38194 47.2706 11.8987C51.0445 16.4154 53.112 22.1142 53.112 28H56Z'
      fill='white'
    />
  </svg>
)

export default Circle
