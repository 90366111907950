// styles/buttons/ButtonOne.js - default button
import styled from 'styled-components'
import { backgroundColors } from '../core/theme'
import { colors } from '../core/colors'

// Base Button, accepts bgColor, textColor, and svgBG
export const ButtonOne = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || '56px'};
  background-color: ${({ bgColor }) => bgColor || backgroundColors.secondary}; /* Dynamic background */
  color: ${({ textColor }) => textColor || colors.white}; /* Dynamic text color */
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  overflow: hidden;
  text-decoration: none; /* Remove underline for link styling */
  cursor: pointer;
`

export default ButtonOne
